import { Button, Icon } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from 'react';
import styled from "styled-components";
import AppContext from '../../context'
import {cloneDeep} from 'lodash';
import { hoa } from "../../api";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import { HOA_CATEGORIES } from "../../constants";
import SelectorHoa from "../SelectorHoa";
import {getCurrentDate} from "../../utils/hoa";

const { get: getHoa } = hoa;

const FormWrapper = styled.div`
  display: flex;
`;

const StyledSelectorHoa = styled(SelectorHoa)`
  width: 100%;
`;

const Add = () => {
  const [adding, setAdding] = useState(false);
  const [subRecordId, setSubRecordId] = useState(null);
  const [selectedSubHOA, setSelectedSubHOA] = useState(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  //Context
  const { hoaDetails, onSubmit } = useContext(AppContext);

  const onClickAddButton = () => {
    setAdding(true);
    setSelectedSubHOA(null)
  }

  const onClickCancel = () => {
    setAdding(false);
    setSelectedSubHOA(null)
  }

  const onClickSave = async () => {
    setLoading(true);
    try {
      const {category, type, code, ein, authorization, city, address1, address2, state, zip, zip4, recordId, name, notes, website } = selectedSubHOA

      let hoaCreationData = cloneDeep(hoaDetails);
      let payload = {
        Assn_Category: category,
        Assn_Type: type,
        Code: code,
        EIN: ein,
        Homeowner_Authorization: authorization,
        Last_Verified_Date: getCurrentDate(),
        Mailing_City: city,
        Mailing_Line1: address1,
        Mailing_Line2: address2,
        Mailing_State: state,
        Mailing_Zip: zip,
        Mailing_Zip_4: zip4,
        Master_Hoa_Co_ID: recordId,
        Name: name,
        Notes: notes,
        Website: website
      }
      hoaCreationData.SubAssoc.push(payload)
      console.log('hoaCreationData', hoaCreationData)

      await onSubmit(hoaCreationData)

      // const subHoa = await patch(subRecordId, { category: HOA_CATEGORIES.SUB, masterId: recordId });
      // enqueueSnackbar('The Sub HOA has been added', { variant: 'success' });
      // onSuccess(subHoa);
      setSubRecordId(null);
      setSelectedSubHOA(null);
      setAdding(false);
    } catch (e) {
      const message = e.response.data.message;
      enqueueSnackbar(`Cannot add Sub HOA: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoading(false);
  }

  const onChangeHoa = async(id) => {
    setSubRecordId(id)
    const hoa = await getHoa(id)
    if(hoa){
      if(hoa.category === "Master"){
        setSelectedSubHOA(null);
        setSubRecordId(null);
        enqueueSnackbar(`Master HOA can not be attached as a sub association`, { variant: 'error' });
      }
      else{
        setSelectedSubHOA(hoa)
      }
    }
  }

  if (!adding) {
    return <Button color="default" onClick={onClickAddButton}><Icon>add_circle</Icon>Add</Button>
  }

  return (
    <FormWrapper>
      {loading && <LoadingOverlay />}
      <StyledSelectorHoa onChange={onChangeHoa} value={subRecordId} type="Sub" label="HOA Name" />
      <Button disabled={selectedSubHOA ? false : true} color="primary" onClick={onClickSave}>Submit</Button>
      <Button color="secondary" onClick={onClickCancel}>Cancel</Button>
    </FormWrapper>
  );
}

export default Add;
