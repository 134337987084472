import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { mgmtCompany } from "../../../api/endpoints";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import { CONTACT_TYPES } from "../../../constants";
import Contacts from "../../Contacts";

const { get: mgmtCompanyGet } = mgmtCompany;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledContacts = styled(Contacts)`
  height: 100%;
`;

const TypeWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const ContactsTab = ({
  hoaPrimaryContactId,
  hoaRecordId,
  mgmtCompanyRecordId,
  onChangePrimaryHoa,
  onCreate,
  contacts
}) => {
  const [loading, setLoading] = useState(false);
  const [mgmtCompanyPrimaryContactId, setMgmtCompanyPrimaryContactId] = useState();

  useEffect(() => {
    if (!mgmtCompanyRecordId) {
      setMgmtCompanyPrimaryContactId(null);
      return;
    }

    setLoading(true);

    const request = mgmtCompanyGet(mgmtCompanyRecordId);

    (async () => {
      try {
        const { primaryContactId } = await request;
        setMgmtCompanyPrimaryContactId(primaryContactId);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();

    return () => { request.cancel(); setLoading(false) };
  }, []);

  const hoaContacts = contacts.filter(function(el, index){
    if(el.type === CONTACT_TYPES.hoa && !el.category){
        return el
    }
  })

  const mgmtContacts = contacts.filter(function(el, index){
    if(el.type === CONTACT_TYPES.mgmtCompany && !el.category){
        return el
    }
  })

  return (
    <Wrapper>
      <TypeWrapper>
        <StyledContacts
          contacts={hoaContacts}
          onChangePrimary={onChangePrimaryHoa}
          primaryContactId={hoaPrimaryContactId}
          recordId={hoaRecordId}
          type={CONTACT_TYPES.hoa}
          onCreate={onCreate}
        />
      </TypeWrapper>
      <TypeWrapper>
        {loading && <LoadingOverlay />}
        <StyledContacts
          contacts={mgmtContacts}
          onChangePrimary={setMgmtCompanyPrimaryContactId}
          primaryContactId={mgmtCompanyPrimaryContactId}
          recordId={mgmtCompanyRecordId}
          onCreate={onCreate}
          type={CONTACT_TYPES.mgmtCompany}
        />
      </TypeWrapper>
    </Wrapper>
  );
}

export default ContactsTab;
