import { Button, FormControl, Hidden, TextField, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText  } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Slide from '@mui/material/Slide';
import { useHistory, useLocation } from "react-router";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { ROUTES } from "../../../constants";
import { hoa, subdivision } from '../../../api'
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import { HOA_CATEGORIES, HOA_TYPES, STATES } from "../../../constants";
import SelectorHoa from "../../SelectorHoa";

const { hoaIngestion: createHoa, get: getHoaById } = hoa;

const { HOA_MASTER } = ROUTES;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextField = styled(TextField)`
  flex-basis: ${props => props.width}%
`;

const StyledFormControl = styled(FormControl)`
  flex-basis: ${props => props.width}%;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

const SubsWrapper = styled.div`
  position: relative;
`;

const StyledSelectorHoa = styled(SelectorHoa)`
  width: 100%;
`;

const STATE_UNKNOWN = 'UNKNOWN';

const CreateHoa = ({
  standalone,
  code,
  isValid,
  mgmtCompanyId,
  recordId,
  onChangeHasChanges,
  changeShow,
  hoaItemsList,
  onDelete,
  onUpdate,
  isSubdivision,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [authorization, setAuthorization] = useState('');
  const [category, setCategory] = useState('');
  const [city, setCity] = useState('');
  const [ein, setEin] = useState('');
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [masterId, setMasterId] = useState('');
  const [state, setState] = useState('');
  const [type, setType] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const [website, setWebsite] = useState('');
  const [zip, setZip] = useState('');
  const [zip4, setZip4] = useState('');

  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [duplicateId, setDuplicateId] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    resetFromProps();
  }, []);

  useEffect(() => {
    const anythingIsChanged =
      (address1 !== '' || address1 !== null)
      || (address2 !== '' || address2 !== null)
      || (authorization !== '' || authorization !== null)
      || (category !== '' || category !== null)
      || (city !== '' || city !== null)
      || (ein !== '' || ein !== null)
      || (name !== '' || name !== null)
      || (notes !== '' || notes !== null)
      || (masterId !== '' || masterId !== null)
      || (state !== null || state !== STATE_UNKNOWN)
      || (type !== '' || type !== null)
      || (website !== '' || website !== null)
      || (zip !== '' || zip !== null)
      || (zip4 !== '' || zip4 !== null)
    setHasChanges(anythingIsChanged);
  }, [
    address1,
    address2,
    authorization,
    category,
    city,
    ein,
    name,
    notes,
    masterId,
    state,
    type,
    website,
    zip,
    zip4,
  ]);

  useEffect(() => {
    if(!standalone)
      onChangeHasChanges(hasChanges);
  }, [hasChanges]);

  useEffect(() => {
    if (category && category !== HOA_CATEGORIES.SUB) {
      setMasterId(null);
    }
  }, [category]);

  const resetFromProps = () => {
    setAddress1('');
    setAddress2('');
    setAuthorization(false);
    setCategory(HOA_CATEGORIES.STANDALONE);
    setCity('');
    setEin('');
    setName('');
    setNotes('');
    setMasterId('')
    setState('');
    setType(HOA_TYPES.HOA);
    setUpdatedDate('');
    setWebsite('');
    setZip('');
    setZip4('');
  }

  const onChangeAddress1 = event => {
    setAddress1(event.target.value.toUpperCase());
  }

  const onChangeAddress2 = event => {
    setAddress2(event.target.value.toUpperCase());
  }

  const onChangeAuthorization = event => {
    setAuthorization(event.target.value);
  }

  const onChangeCategory = event => {
    setCategory(event.target.value);
  }

  const onChangeCity = event => {
    setCity(event.target.value.toUpperCase());
  }

  const onChangeEin = event => {
    setEin(event.target.value);
  }

  const onChangeName = event => {
    setName(event.target.value.toUpperCase());
  }

  const onChangeNotes = event => {
    setNotes(event.target.value);
  }

  const onChangeState = event => {
    setState(event.target.value);
  }

  const onChangeType = event => {
    setType(event.target.value);
  }

  const onChangeWebsite = event => {
    setWebsite(event.target.value.toUpperCase());
  }

  const onChangeZip = event => {
    setZip(event.target.value);
  }

  const onChangeZip4 = event => {
    setZip4(event.target.value);
  }

  const handleClose = () => {
    setOpen(false);
    setDuplicateId(null)
  };

  const handleSelectNo = () => {
    const id = duplicateId;
    setOpen(false);
    setDuplicateId(null)
    if(!isSubdivision){  
      changeShow(true)
    }
    history.push(`${HOA_MASTER}/${id}${location.search}${location.hash}`);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      let hoaCreationData = {
        "MGMT": {
          "Master_Mgmt_Co_ID": null,
          "Record_IDN": null,
          "ExtSystem.User_ID": "",
          "EIN": "",
          "Code": "",
          "Name": "",
          "Mailing_Line1": "",
          "Mailing_Line2": "",
          "Mailing_City": "",
          "Mailing_State": "",
          "Mailing_Zip": "",
          "Mailing_Zip_4": "",
          "Website": "",
          "Notes": "",
          "Verified_Date": ""
        },
        "Contacts": [],
        "ContactsDelete": [],
        "OrderingInfoDelete": [],
        "OrderingInfo": [],
        "HOA": {
          "Master_Hoa_Co_ID": null,
          "Code": null,
          "Valid_HOA": "N",
          "EIN": ein,
          "Last_Mod_User_Id": "",
          "Last_Mod_Ext_Userid": "",
          "Name": name,
          "Verification_Method": "",
          "Last_Verified_Date": "",
          "Assn_Type": type,
          "Mailing_Line1": address1,
          "Mailing_Line2": address2,
          "Mailing_City": city,
          "Mailing_State": state,
          "Mailing_Zip": zip,
          "Mailing_Zip_4": zip4,
          "Website": website,
          "Payee_Type": "",
          "Homeowner_Authorization": authorization,
          "Notes": notes,
          "Assn_Category": category,
          "Record_IDN": ""
        },
        "UnlinkSubAssoc": [],
        "SubAssoc": []
      }

      const PDBResponse = await createHoa(hoaCreationData)

      console.log('PDB RES', PDBResponse);

      if (PDBResponse.data.status === "Failed: Duplicate Record Found") {
        console.log('Conflicting or duplicate data has been found in the system')
        if (PDBResponse.data.Detail && Object.entries(PDBResponse.data.Detail).length) {
          let HOADouble = false;

          for (let i = 0; i < Object.entries(PDBResponse.data.Detail).length; i++) {
            let el = Object.entries(PDBResponse.data.Detail)[i];
            // Duplicate found in HOA
            if (el[0] === 'HOA' && el[1].status === 'Duplicate') {
              console.log('HOA Duplicate', el[1].status);
              HOADouble = true;
            }
          }

          if(HOADouble){
            const id = PDBResponse.data.Detail.HOA.Data.HOA[0].Master_Hoa_Co_ID;
            setDuplicateId(id)
            setOpen(true)
          }
        }
        setLoading(false)
        return;
      }

      if (PDBResponse.status === 'Unique key Constraint Violate') {
        setLoading(false)
        console.log('Handling Unique key Constraint Violation Error!');
        return;
      }

      if (PDBResponse.data.status === 'success') {
        const returnedCreatedHoaData = await getHoaById(PDBResponse.data.output.HOA.Master_Hoa_Co_ID)
        if (!isSubdivision) {
          hoaItemsList(returnedCreatedHoaData)
          enqueueSnackbar('The HOA has been created', { variant: 'success' });
          resetFromProps();
          setHasChanges(false);
        }
        else {
          isSubdivision(PDBResponse.data.output.HOA.Master_Hoa_Co_ID);
        }
      } else {
        enqueueSnackbar(` ${PDBResponse.data.status} `, { variant: 'error' });
      }
      changeShow(true)
    } catch (e) {

      try {
        const message = e.response.data.message;
        enqueueSnackbar(`Cannot update the HOA: ${message ?? 'Unknown error'}`, { variant: 'error' });
      } catch (error) {
        if (!isSubdivision) {
          enqueueSnackbar(`failed to create hoa`, { variant: 'error' });
        }
      }
      setLoading(false);
    }
    setLoading(false);
  }

  const onCancelClick = () => {
    if (!isSubdivision) {
      changeShow(true)
      onChangeHasChanges(false)
      resetFromProps()
    }
    else {
      isSubdivision();
    }
  }

  return <Wrapper>
    {loading && <LoadingOverlay />}
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Duplicate data"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
        Conflicting or duplicate data has been found in the system for the (Name, State) you are attempting to create. Do you want to edit your inputs to ensure conflicts are addressed?  If you select "Yes", you may go back and modify your information to resolve the conflicts and then save your changes. If you select "No", your HOA information will be updated and replaced with the data in our system
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Yes</Button>
        <Button onClick={handleSelectNo}>No</Button>
      </DialogActions>
    </Dialog>
    <div class="set1_tab">
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Name" value={name || ''} onChange={onChangeName} />
          </div>
          <div class="form_group rg">
            <StyledFormControl width={15} size="small">
              <InputLabel>HOA Type</InputLabel>
              <Select
                value={type}
                onChange={onChangeType}
              >
                {Object.keys(HOA_TYPES).map((type) => <MenuItem key={type} value={HOA_TYPES[type]}>{HOA_TYPES[type]}</MenuItem>)}
              </Select>
            </StyledFormControl>
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Address 1" value={address1 || ''} onChange={onChangeAddress1} />
          </div>
          <div class="form_group rg">
            {/* value={code || ''} */}
            <StyledTextField style={{ display: 'none' }} width={15} size="small" label="HOA Code" />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Address 2" value={address2 || ''} onChange={onChangeAddress2} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor sec mt-3">
          <div class="twnf">
            <StyledTextField width={50} size="small" label="City" value={city || ''} onChange={onChangeCity} />
          </div>
          <div class="twnf">
            <StyledFormControl width={15} size="small">
              <InputLabel>State</InputLabel>
              <Select
                value={state}
                onChange={onChangeState}
              >
                <MenuItem value={STATE_UNKNOWN}>-UNKNOWN-</MenuItem>
                {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
              </Select>
            </StyledFormControl>
          </div>
          <div class="form_group twnf">
            <StyledTextField width={15} size="small" label="Zip" value={zip || ''} onChange={onChangeZip} />
          </div>
          <div class="form_group twnf">
            <StyledTextField width={15} size="small" label="Zip + 4" value={zip4 || ''} onChange={onChangeZip4} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor trd mt-3">
          <div class="form_group fif">
            <StyledTextField width={50} size="small" label="Website" value={website || ''} onChange={onChangeWebsite} />
          </div>
          <div class="form_group fif">
            <StyledTextField width={50} size="small" label="EIN" value={ein || ''} onChange={onChangeEin} />
          </div>
        </div>
      </Row>
    </div>

    <div class="set2_tab">
      <h3 class="hd_tg">Additional HOA Information</h3>
      <Row>
        <div class="wor trd mt-3">
          <div class="form_group fif">
            <StyledFormControl width={50} size="small">
              <InputLabel>HOA Category</InputLabel>
              <Select
                value={category}
                onChange={onChangeCategory}
              >
                {Object.keys(HOA_CATEGORIES).map((type) => <MenuItem key={type} value={HOA_CATEGORIES[type]}>{HOA_CATEGORIES[type]}</MenuItem>)}
              </Select>
            </StyledFormControl>
          </div>
          <div class="form_group fif">
            {/* <StyledSelectorHoa  disabled={category !== HOA_CATEGORIES.SUB} onChange={setMasterId} value={masterId} /> */}
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor cont_st1 mt-3">
          <div class="form_group fif">
            <StyledTextField width={70} size="small" label="HOA Notes" multiline rows={9} value={notes || ''} onChange={onChangeNotes} />
          </div>
          <div class="form_group fif">
            <Column>
              <StyledFormControl width={100} size="small">
                <InputLabel>Homeowner Authorization</InputLabel>
                <Select
                  value={authorization}
                  onChange={onChangeAuthorization}
                >
                  <MenuItem value={true}>Yes</MenuItem>)
                  <MenuItem value={false}>No</MenuItem>)
                </Select>
              </StyledFormControl>
              {/* <div class="form_group inrfif mt-3">
              <StyledTextField style = {{visibility: 'hidden'}} disabled={true}  label="Last Verified Date" value={updatedDate || ''} width={15} />
            </div> */}
            </Column>
          </div>
        </div>
      </Row>
      <Row>
        <div class="btn_box rg">
          <Button variant="contained" color="primary" disabled={!hasChanges} onClick={onSubmit}>Create</Button>
          {/* <Button color="secondary" disabled={!hasChanges} onClick={resetFromProps}>Cancel</Button> */}
          <Button color="secondary" disabled={!hasChanges} onClick={onCancelClick}>Cancel</Button>
        </div>
      </Row>
    </div>
  </Wrapper>;
}

export default CreateHoa;
