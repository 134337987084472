import {
    del as requestDel,
    get as requestGet,
    patch as requestPatch,
    post as requestPost,
    put as requestPut,
  } from '../base/request';
  import options from '../servers/apiV5';

//   export const getPreSignedUrl = (documentName) =>
//   requestGet(`documents/file/presignedUrl/${documentName}`, options())

  export const upload = (url, payload) => {
      requestPut(url, options(), payload)
  }

  export const getPreSignedUrl = (fileName, hoaId) => requestPost(`documents/file/presignedUrl/`, options(), fileName, hoaId);