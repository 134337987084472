import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { mgmtCompany } from "../../../api";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import MgmtCompany from "../../../components/MgmtCompany";
import AppContext from '../../../context';
import {cloneDeep} from 'lodash';
import CompanyDeleteButton from "../../../components/CompanyDeleteButton";
import {getCurrentDate} from "../../../utils/hoa";

const { get: getMgmtCompany, patch: patchMgmtCompany } = mgmtCompany;

const StyledMgmtCompany = styled(MgmtCompany)`
  padding-bottom: 30px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const CompanyDetails = ({
  mgmtCompanyRecordId,
  onChangeHasChanges,
  onUpdate,
  onDelete,
  loading,
  details
}) => {
  const [entity, setEntity] = useState({});
  const [hasChangesId, setHasChangesId] = useState(false);
  const [hasChangesEntity, setHasChangesEntity] = useState(false);

  //Context
  const { hoaDetails, onSubmit } = useContext(AppContext);

  useEffect(() => {
    onChangeHasChanges(hasChangesId || hasChangesEntity);
  }, [hasChangesId, hasChangesEntity]);

  const { enqueueSnackbar } = useSnackbar();

  const onSave = async (data) => {
    let hoaCreationData = cloneDeep(hoaDetails);
    hoaCreationData.MGMT = {
      ...hoaDetails.MGMT,
      EIN: data.ein,
      Mailing_City: data.city,
      Mailing_Line1: data.address,
      Mailing_State: data.state,
      Mailing_Zip: data.zip,
      Mailing_Zip_4: data.zip4,
      Master_Mgmt_Co_ID: mgmtCompanyRecordId,
      Name: data.name,
      Notes: data.notes,
      Website: data.website,
      Verified_Date: getCurrentDate(),
    }

    await onSubmit(hoaCreationData)
    onUpdate(hoaCreationData);
  }

  return <Wrapper>
    {loading && <LoadingOverlay />}
    {Boolean(!mgmtCompanyRecordId) || <StyledMgmtCompany {...details} onChangeHasChanges={setHasChangesEntity} onSave={onSave} />}
    <CompanyDeleteButton name={details.name} recordId={mgmtCompanyRecordId} onDelete={onDelete} />
  </Wrapper>;
}

export default CompanyDetails;
