import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { hoa, mgmtCompany } from "../api/endpoints";
import OrderingInfoAddEditButton from "../components/OrderingInfoAddEditButton";
import OrderingInfoDeleteButton from "../components/OrderingInfoDeleteButton";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import Overlay from "../components/Overlay";
import { COLUMN_WIDTH, ORDERING_INFO_TYPES } from "../constants";
import GridCellExpand from "../components/GridCellExpand";
import PropTypes from 'prop-types';

const { orderingInfoGet: hoaOrderingInfoGet } = hoa;
const { orderingInfoGet: mgmtCompanyOrderingInfoGet } = mgmtCompany;

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const OrderingInfos = ({ orderingInfo, className, recordId, type }) => {
  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }
  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };
  const columns = [
    {
      field: 'name',
      headerName: 'Contact Name',
      minWidth: COLUMN_WIDTH.NAME,
      filterable: false,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      field: 'deliveryType',
      headerName: 'Delivery Type',
      minWidth: COLUMN_WIDTH.DELIVERY_TYPE,
      filterable: false,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      field: 'processMethod',
      headerName: 'Process Method',
      minWidth: COLUMN_WIDTH.PROCESS_METHOD,
      filterable: false,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      field: 'phonePrimary',
      headerName: 'Primary Phone',
      minWidth: COLUMN_WIDTH.PRIMARY_PHONE,
      filterable: false,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      field: 'phoneSecondary',
      headerName: 'Secondary Phone',
      minWidth: COLUMN_WIDTH.SECONDARY_PHONE,
      filterable: false,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      field: 'fax',
      headerName: 'Fax',
      minWidth: COLUMN_WIDTH.FAX,
      filterable: false,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      field: 'address',
      headerName: 'Mailing address',
      minWidth: COLUMN_WIDTH.ADDRESS,
      flex:1,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: COLUMN_WIDTH.EMAIL,
      flex:1,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'website',
      headerName: 'Website',
      minWidth: COLUMN_WIDTH.WEBSITE,
      flex: 1,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row }) => <ActionCell>
        <OrderingInfoAddEditButton entity={row} linkedRecordId={recordId} onEdit={onEdit} type={type} />
        <OrderingInfoDeleteButton
          orderingInfoId={row.recordId}
          linkedRecordId={recordId}
          onDelete={onDelete(row.recordId, recordId)}
          type={type}
        />
      </ActionCell>,
      filterable: false,
      minWidth: COLUMN_WIDTH.ACTION,
      flex: 1
    },
  ];

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!recordId || !type) {
      setItems([]);
      return;
    }

    const orderingInfoList = orderingInfo.filter(function(el, index){
      if(el.type === type){
          return el
      }
    })
    setItems(orderingInfoList)
  }, [recordId, type, orderingInfo]);

  const onDelete = (orderingInfoId, linkedRecordId) => () => {
    setItems(items.filter((item) => item.recordId !== orderingInfoId));
  }

  const onAdd = async (entity) => {
    setItems([...items, entity]);
  }

  const onEdit = (entity) => {
    setItems(items.map(item => {
      if (entity.recordId === item.recordId) {
        return entity;
      }
      return item;
    }));
  }

  return <Wrapper className={className}>
    <div class="set4_tab_ne">
      {loading && <LoadingOverlay />}
      {!recordId && <Overlay text={`The ${type === ORDERING_INFO_TYPES.hoa ? 'HOA' : 'Management Company'} is not set.`} />}
      <HeaderWrapper>
        <h3 class="hd_tg">{type === ORDERING_INFO_TYPES.hoa ? 'HOA' : 'Management Company'} Ordering Info</h3>
        <OrderingInfoAddEditButton onAdd={onAdd} linkedRecordId={recordId} type={type} />
      </HeaderWrapper>
      <div style={{ height: '300px', width: '100%' }}>
        {/* items.map - brute fix to keep the ID for the DataGrid component. */}
        <DataGrid rows={items.map((record) => ({ id: record.recordId, ...record }))} columns={columns} rowsPerPageOptions={[]} />
      </div>
    </div>
  </Wrapper>
}

export default OrderingInfos;
