import { DataGrid } from "@material-ui/data-grid";
import React from 'react';
import styled from "styled-components";
import ActionButtons from "./ActionButtons";
import GridCellExpand from "../GridCellExpand"
import PropTypes from 'prop-types';
import { COLUMN_WIDTH } from "../../constants";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const div_div = {
  height: '500px',
}

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const Hoas = ({ items, ofSubdivision, ofCompany, onDisconnect }) => {
  function renderCellExpand(params) {
    // const county = FIPS[params?.row?.fips] ? FIPS[params?.row?.fips].county : 'INCORRECT FIPS CODE';
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }
  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };
  const columns = [
    {
      field: 'name',
      headerName: 'HOA Name',
      renderCell: ({ row: { name } }) => <GridCellExpand value={name} width={300} />,
      minWidth: COLUMN_WIDTH.NAME,
      flex: 1,
      filterable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: COLUMN_WIDTH.TYPE,
      filterable: false,
    },
    {
      field: 'id',
      headerName: 'HOA Code',
      minWidth: COLUMN_WIDTH.CODE,
      filterable: false,
    },
    {
      field: 'addressFull',
      headerName: 'Address',
      renderCell: ({ row: { addressFull } }) => <GridCellExpand value={addressFull} width={300} />,
      minWidth: COLUMN_WIDTH.ADDRESS,
      filterable: false,
      flex: 1
    },
    {
      field: 'city',
      headerName: 'City',
      renderCell: ({ row: { city } }) => <GridCellExpand value={city} width={150} />,
      minWidth: COLUMN_WIDTH.CITY,
      filterable: false,
    },
    {
      field: 'state',
      headerName: 'State',
      minWidth: COLUMN_WIDTH.STATE,
      filterable: false,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row: { recordId } }) => <ActionButtons subdivisionId={ofSubdivision} managementId={ofCompany} onDisconnect={handleOnDisconnect(recordId)} hoaId={recordId} />,
      minWidth: COLUMN_WIDTH.ACTION,
      filterable: false,
    },
  ];

  const handleOnDisconnect = (recordId) => () => {
    onDisconnect(recordId);
  }

  return <Wrapper>
    <div class="set4_tab">
      <HeaderWrapper>
        <h3 class="hd_tg">Associated HOAs</h3>
      </HeaderWrapper>
      <div class="tab_dev">
        <div style={{ height: '100%', width: '100%' }}>
          <div class="div_row">
            <DataGrid rows={items.map((record) => ({ id: record.recordId, ...record }))} columns={columns} pageSize={20} />
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
}

export default Hoas;
