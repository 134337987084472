import React from 'react';
import styled from "styled-components";
import { ORDERING_INFO_TYPES } from "../../../constants";
import OrderingInfos from "../../OrderingInfos";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledOrderingInfos = styled(OrderingInfos)`
  height: 100%;
`;

const TypeWrapper = styled.div`
  flex: 1;
  position: relative;
  margin-top:30px;
`;

const OrderingInfo = ({ orderingInfo, mgmtCompanyRecordId }) => {
  return <Wrapper>
    <TypeWrapper>
      <StyledOrderingInfos
        orderingInfo={orderingInfo}
        recordId={mgmtCompanyRecordId}
        type={ORDERING_INFO_TYPES.mgmtCompany}
      />
    </TypeWrapper>
  </Wrapper>
}

export default OrderingInfo;
