import { Button, Icon } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from 'react';
import {cloneDeep} from 'lodash';
import AppContext from '../../context'
import { hoa } from "../../api";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import Add from "./Add";

const SubAssociation = ({ recordId }) => {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  //Context
  const { hoaDetails, transformedDetails, onSubmit } = useContext(AppContext);

  const subAssociations = transformedDetails.SubAssoc

  const columns = [
    {
      field: 'name',
      flex: 7,
      headerName: 'HOA Name',
    },
    {
      field: 'action',
      flex: 1,
      headerName: 'Action',
      renderCell: ({ row: { recordId: subRecordId }}) =>
        <Button onClick={onRemove(subRecordId)} color="secondary"><Icon>delete</Icon></Button>
      ,
    },
  ];

  const onRemove = (subRecordId) => async () => {
    setLoading(true);
    let hoaCreationData = cloneDeep(hoaDetails);
    hoaCreationData.UnlinkSubAssoc.push({Master_Hoa_Co_ID: subRecordId, Record_IDN: null})
    console.log('hoaCreationData', hoaCreationData)
    await onSubmit(hoaCreationData)

    // await patch(subRecordId, { category: HOA_CATEGORIES.STANDALONE, masterId: null });
    // setItems(items.filter(({ recordId: id }) => id !== subRecordId));
    // enqueueSnackbar('The Sub HOA has been removed', { variant: 'success' });
    setLoading(false);
  }

  return <div>
    {loading && <LoadingOverlay />}
    <Add />
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid disableColumnFilter rows={subAssociations} columns={columns} rowsPerPageOptions={[]} />
    </div>
  </div>;
}

export default SubAssociation;
