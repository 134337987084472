import {
  Button,
  FormControl,
  Icon,
  InputLabel,
  makeStyles,
  Select,
  TextField,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import { subdivision } from "../api/endpoints";
import { subdivisionConnect } from "../api/endpoints/hoa";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import SubdivisionAddButton from "../components/SubdivisionAddButton";
import { COLUMN_WIDTH, FIPS, STATES } from "../constants";
import SubdivisionOtherHoas from "./SubdivisionOtherHoas";
import { useSnackbar } from "notistack";
import GridCellExpand from "../components/GridCellExpand";
import PropTypes from 'prop-types'

const { getAll } = subdivision;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const STATE_ANY = 'ANY';
const STATE_UNKNOWN = 'UNKNOWN';

const useStyles = makeStyles({
  highlightRow: {
    backgroundColor: '#dddddd',
  },
});

const SubdivisionSearch = ({ defaultState, forHoa, onAdd, subdivisionsAttached }) => {
  function renderCellExpand(params) {
    const county = FIPS[params?.row?.fips] ? FIPS[params?.row?.fips].county : 'INCORRECT FIPS CODE';
    return (
      <GridCellExpand value={params.value || county} width={params.colDef.computedWidth} />
    );
  }
  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };
  const columns = [
    {
      field: "name",
      headerName: "Subdivision Name",
      renderCell: renderCellExpand,
      minWidth: COLUMN_WIDTH.NAME,
      flex: 1,
      filterable: false,
    },
    {
      field: "normalizedName",
      headerName: "Normalized Name",
      renderCell: renderCellExpand,
      flex: 1,
      minWidth: COLUMN_WIDTH.NAME,
      filterable: false,
    },
    {
      field: "fips",
      headerName: "FIPS",
      minWidth: COLUMN_WIDTH.FIPCD,
      filterable: false,
      flex: 1
    },
    {
      field: "county",
      headerName: "County",
      renderCell: renderCellExpand,
      minWidth: COLUMN_WIDTH.COUNTY,
      filterable: false,
      flex: 1
    },
    {
      field: "state",
      headerName: "State",
      renderCell: ({ row: { fips } }) =>
        FIPS[fips] ? FIPS[fips].state : "INCORRECT FIPS CODE",
      minWidth: COLUMN_WIDTH.STATE,
      filterable: false,
      flex: 1
    },
    {
      field: "hasFee",
      headerName: "Has Fees",
      renderCell: ({ row: { hasFee } }) => (hasFee ? "Yes" : "No"),
      minWidth: COLUMN_WIDTH.HASFEE,
      filterable: false,
      flex: 1
    },
    {
      field: "otherHoas",
      headerName: "Other HOAs",
      renderCell: ({ row: { recordId } }) => (
        <SubdivisionOtherHoas subdivisionId={recordId} />
      ),
      minWidth: COLUMN_WIDTH.OTHER,
      filterable: false,
      flex: 1
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: ({ row: { recordId } }) => (
        <Button
          color="primary"
          disabled={isSubdivisionAttached(recordId)}
          onClick={handleOnConnect(recordId)}
        >
          <Icon>link</Icon>
        </Button>
      ),
      minWidth: COLUMN_WIDTH.ACTION,
      filterable: false,
      flex: 1
    },
  ];

  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [requestedAnything, setRequestedAnything] = useState(false);
  const [state, setState] = useState('');
  const [fips, setFips] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setState(defaultState);
    setItems([]);
    setRequestedAnything(false);
  }, [defaultState]);
  useEffect(() => {
    setState(defaultState);
    setItems([]);
    setName('');
    setFips('');
    setRequestedAnything(false);
  }, [forHoa]);
  const handleFipsChange = event => {
    setFips(event.target.value);
    console.log(fips);
  }
  const handleNameChange = event => {
    setName(event.target.value);
  }

  const handleStateChange = event => {
    setState(event.target.value);
  }
  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      handleOnSearch();
    }
  }

  const handleOnSearch = async () => {
    setLoading(true);
    try {
      const response = await getAll({ name, state: state === STATE_UNKNOWN ? '' : state, fips });
      setItems(response.items);
      setRequestedAnything(true);
    }
    catch (e) {
      enqueueSnackbar('Unable to Search! Try Again', { variant: 'error' });
    }
    setLoading(false);
  }

  const handleOnConnect = (subdivisionId) => async () => {
    setLoading(true);
    try {
      const subdivision = await subdivisionConnect(forHoa, subdivisionId);
      onAdd(subdivision);
      enqueueSnackbar('Subdivision has been connected successfully', { variant: 'success' });
    }
    catch (e) {
      enqueueSnackbar('Unable to connect! Try Again', { variant: 'error' });
    }
    setLoading(false);
  }

  const isSubdivisionAttached = (recordId) =>
    Boolean(subdivisionsAttached.find(subdivision => subdivision.recordId === recordId));
  const CountyList = Object.keys(FIPS).map(function (key) {
    if (state === FIPS[key].state)
      return <MenuItem key={key} value={key}>{FIPS[key].county}</MenuItem>
  });
  return <Wrapper>
    {loading && <LoadingOverlay />}

    <Header>
      <div class="flx_arg">
        <div class="dif_one">
          <TextField
            label="Subdivision Name"
            onChange={handleNameChange}
            onKeyUp={handleKeyUp}
            size="small"
            value={name}
            variant="outlined"
          />
        </div>
        <div style={{ display: 'flex' }} class="dif_two">
          <FormControl variant="outlined" size="small">
            <InputLabel>State</InputLabel>
            <Select
              value={state}
              onChange={handleStateChange}
            >
              <MenuItem value={STATE_UNKNOWN}>
                <em>-UNKNOWN-</em>
              </MenuItem>
              {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div style={{ display: 'flex' }} class="dif_two">
          <FormControl variant="outlined" size="small">
            <InputLabel>County</InputLabel>
            <Select
              value={fips}
              onChange={handleFipsChange}
            >
              <MenuItem value={''}>
                <em>-County-</em>
              </MenuItem>
              {state === STATE_ANY ?
                <MenuItem value={''}>
                  <em>Select A State First</em>
                </MenuItem>
                : <></>
              }
              {CountyList}
            </Select>
          </FormControl>
        </div>
        <div class="dif_thre">
          <Button color="primary" onClick={handleOnSearch}>
            <Icon>search</Icon>
          </Button>
        </div>
        <div class="dif_four">
          <SubdivisionAddButton hoaId={forHoa} onAdd={onAdd} />
        </div>
      </div>

    </Header>

    {/* items.map - brute fix to keep the ID for the DataGrid component. */}
    {requestedAnything && <div style={{ marginTop: '20px', height: '300px', width: '100%' }} class="div_row">
      <DataGrid
        columns={columns}
        getRowClassName={params => isSubdivisionAttached(params.row.recordId) ? classes.highlightRow : ''}
        rows={items.map((record) => ({ id: record.recordId, ...record }))}
        rowsPerPageOptions={[]}
      />
    </div>}
  </Wrapper>;
}

export default SubdivisionSearch;
