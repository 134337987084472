import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import AppContext from '../../../context'
import { hoa } from '../../../api'
import {cloneDeep} from 'lodash';
import HoaDeleteButton from "../../../components/HoaDeleteButton";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import Overlay from "../../../components/Overlay";
import { HOA_CATEGORIES, HOA_TYPES, STATES } from "../../../constants";
import SelectorHoa from "../../SelectorHoa";
import SubAssociation from "../../SubAssociation";
import {getCurrentDate} from "../../../utils/hoa";

const { patch: patchHoa, get: getHoa } = hoa;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextField = styled(TextField)`
  flex-basis: ${props => props.width}%
`;

const StyledFormControl = styled(FormControl)`
  flex-basis: ${props => props.width}%;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const SubsWrapper = styled.div`
  position: relative;
`;

const StyledSelectorHoa = styled(SelectorHoa)`
  width: 100%;
`;

const STATE_UNKNOWN = 'UNKNOWN';

const HoaDetails = ({
  code,
  mgmtCompanyId,
  recordId,
  onChangeHasChanges,
  onDelete,
  onUpdate,
  loading,
  ...props
}) => {
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [addressFull, setAddressFull] = useState('');
  const [authorization, setAuthorization] = useState('');
  const [category, setCategory] = useState('');
  const [city, setCity] = useState('');
  const [ein, setEin] = useState('');
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [masterId, setMasterId] = useState('');
  const [state, setState] = useState('');
  const [type, setType] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const [website, setWebsite] = useState('');
  const [zip, setZip] = useState('');
  const [zip4, setZip4] = useState('');

  const [hasChanges, setHasChanges] = useState(true);
  const [hoaValid, setValid] = useState()

  const { enqueueSnackbar } = useSnackbar();

  //Context
  const { hoaDetails, onSubmit } = useContext(AppContext);

  useEffect(() => {
    resetFromProps();
  }, [
    props.address1,
    props.address2,
    props.addressFull,
    props.authorization,
    props.category,
    props.city,
    props.ein,
    props.name,
    props.notes,
    props.masterId,
    props.state,
    props.type,
    props.website,
    props.zip,
    props.zip4,
    props.isValid,
    recordId,
  ]);

  useEffect(() => {
    const anythingIsChanged =
      (address1 !== props.address1 && (address1 !== '' || props.address1 !== null))
      || (address2 !== props.address2 && (address2 !== '' || props.address2 !== null))
      || (authorization !== props.authorization && (authorization !== '' || props.authorization !== null))
      || (category !== props.category && (category !== '' || props.category !== null))
      || (city !== props.city && (city !== '' || props.city !== null))
      || (ein !== props.ein && (ein !== '' || props.ein !== null))
      || (name !== props.name && (name !== '' || props.name !== null))
      || (notes !== props.notes && (notes !== '' || props.notes !== null))
      || (masterId !== props.masterId && (masterId !== '' || props.masterId !== null))
      || (state !== props.state && (props.state !== null || state !== STATE_UNKNOWN))
      || (type !== props.type && (type !== '' || props.type !== null))
      || (website !== props.website && (website !== '' || props.website !== null))
      || (zip !== props.zip && (zip !== '' || props.zip !== null))
      || (zip4 !== props.zip4 && (zip4 !== '' || props.zip4 !== null))
    setHasChanges(anythingIsChanged);
  }, [
    address1,
    address2,
    authorization,
    category,
    city,
    ein,
    name,
    notes,
    masterId,
    state,
    type,
    website,
    zip,
    zip4,
    props.address1,
    props.address2,
    props.authorization,
    props.category,
    props.city,
    props.ein,
    props.name,
    props.notes,
    props.masterId,
    props.state,
    props.type,
    props.website,
    props.zip,
    props.zip4,
  ]);

  useEffect(() => {
    onChangeHasChanges(hasChanges);
  }, [hasChanges]);

  useEffect(() => {
    if (category && category !== HOA_CATEGORIES.SUB) {
      setMasterId(null);
    }
  }, [category]);

  useEffect(() => {
    let fullAddress = (address1 ?? '') + (address2 ? ` ${address2}` : '') + (address1 || address2 ? ', ' : '') + (city ? `${city}, ` : '') + state + (zip ? `, ${zip}` : '');
    setAddressFull(fullAddress);
  }, [
    address1,
    address2,
    city,
    state,
    zip
  ]);

  const resetFromProps = () => {
    setAddress1(props.address1 ?? props.addressFull);
    setAddress2(props.address2);
    setAddressFull(props.addressFull);
    setAuthorization(props.authorization);
    setCategory(props.category);
    setCity(props.city);
    setEin(props.ein);
    setName(props.name);
    setNotes(props.notes);
    setMasterId(props.masterId)
    setState(props.state || STATE_UNKNOWN);
    setType(props.type);
    setUpdatedDate(props.updatedDate ? props.updatedDate : '');
    setWebsite(props.website);
    setZip(props.zip);
    setZip4(props.zip4);
    setValid(props.isValid);
  }

  const onChangeAddress1 = event => {
    setAddress1(event.target.value.toUpperCase());
  }

  const onChangeAddress2 = event => {
    setAddress2(event.target.value.toUpperCase());
  }

  const onChangeAuthorization = event => {
    setAuthorization(event.target.value);
  }

  const onChangeCategory = event => {
    setCategory(event.target.value);
  }

  const onChangeCity = event => {
    setCity(event.target.value.toUpperCase());
  }

  const onChangeEin = event => {
    setEin(event.target.value);
  }

  const onChangeName = event => {
    setName(event.target.value.toUpperCase());
  }

  const onChangeNotes = event => {
    setNotes(event.target.value);
  }

  const onChangeState = event => {
    setState(event.target.value);
  }

  const onChangeType = event => {
    setType(event.target.value);
  }

  const onChangeWebsite = event => {
    setWebsite(event.target.value.toUpperCase());
  }

  const onChangeZip = event => {
    setZip(event.target.value);
  }

  const onChangeZip4 = event => {
    setZip4(event.target.value);
  }

  const onSubmitHOA = async () => {
    let hoaCreationData = cloneDeep(hoaDetails);
    hoaCreationData.HOA = {
      ...hoaDetails.HOA,
      Assn_Category: category,
      Assn_Type: type,
      Code: code,
      EIN: ein,
      Homeowner_Authorization: authorization,
      Last_Verified_Date: getCurrentDate(),
      Mailing_City: city,
      Mailing_Line1: address1,
      Mailing_Line2: address2,
      Mailing_State: state,
      Mailing_Zip: zip,
      Mailing_Zip_4: zip4,
      Master_Hoa_Co_ID: recordId,
      Name: name,
      Notes: notes,
      Website: website
    }

    await onSubmit(hoaCreationData)
    onUpdate(hoaCreationData);
  }

  return <Wrapper>
    {loading && <LoadingOverlay />}
    <div class="set1_tab">
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Name" value={name || ''} onChange={onChangeName} />
          </div>
          <div class="form_group rg">
            <StyledFormControl width={15} size="small">
              <InputLabel>HOA Type</InputLabel>
              <Select
                value={type}
                onChange={onChangeType}
              >
                {Object.keys(HOA_TYPES).map((type) => <MenuItem key={type} value={HOA_TYPES[type]}>{HOA_TYPES[type]}</MenuItem>)}
              </Select>
            </StyledFormControl>
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Address 1" value={address1 || ''} onChange={onChangeAddress1} />
          </div>
          <div class="form_group rg">
            <StyledTextField width={15} size="small" label="HOA Code" disabled value={recordId || ''} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Address 2" value={address2 || ''} onChange={onChangeAddress2} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor sec mt-3">
          <div class="twnf">
            <StyledTextField width={50} size="small" label="City" value={city || ''} onChange={onChangeCity} />
          </div>
          <div class="twnf">
            <StyledFormControl width={15} size="small">
              <InputLabel>State</InputLabel>
              <Select
                value={state}
                onChange={onChangeState}
              >
                <MenuItem value={STATE_UNKNOWN}>-UNKNOWN-</MenuItem>
                {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
              </Select>
            </StyledFormControl>
          </div>
          <div class="form_group twnf">
            <StyledTextField width={15} size="small" label="Zip" value={zip || ''} onChange={onChangeZip} />
          </div>
          <div class="form_group twnf">
            <StyledTextField width={15} size="small" label="Zip + 4" value={zip4 || ''} onChange={onChangeZip4} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor trd mt-3">
          <div class="form_group fif">
            <StyledTextField width={50} size="small" label="Website" value={website || ''} onChange={onChangeWebsite} />
          </div>
          <div class="form_group fif">
            <StyledTextField width={50} size="small" label="EIN" value={ein || ''} onChange={onChangeEin} />
          </div>
        </div>
      </Row>
    </div>

    <div class="set2_tab">
      <h3 class="hd_tg">Additional HOA Information</h3>
      <Row>
        <div class="wor trd mt-3">
          <div class="form_group fif">
            <StyledFormControl width={50} size="small">
              <InputLabel>HOA Category</InputLabel>
              <Select
                value={category}
                onChange={onChangeCategory}
              >
                {Object.keys(HOA_CATEGORIES).map((type) => <MenuItem key={type} value={HOA_CATEGORIES[type]}>{HOA_CATEGORIES[type]}</MenuItem>)}
              </Select>
            </StyledFormControl>
          </div>
          <div class="form_group fif">
            <StyledSelectorHoa disabled={category !== HOA_CATEGORIES.SUB} type="Master" onChange={setMasterId} value={masterId} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor cont_st mt-3">
          <div class="form_group fif">
            <StyledTextField width={70} size="small" label="HOA Notes" multiline rows={9} value={notes || ''} onChange={onChangeNotes} />
          </div>
          <div class="form_group fif">
            <Column>
              <StyledFormControl width={100} size="small">
                <InputLabel>Homeowner Authorization</InputLabel>
                <Select
                  value={authorization}
                  onChange={onChangeAuthorization}
                >
                  <MenuItem value={true}>Yes</MenuItem>)
                  <MenuItem value={false}>No</MenuItem>)
                </Select>
              </StyledFormControl>
              <div class="form_group inrfif mt-3">
                <StyledTextField disabled label="Last Verified Date" value={updatedDate || ''} width={15} />
              </div>
            </Column>
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor cont_clo mt-3">
          <div class="cont_set">
            <FormControl>
              <FormLabel><p>HOA has Management Company</p></FormLabel>
              <RadioGroup row value={Boolean(mgmtCompanyId)} disabled>
                <FormControlLabel value={true} disabled control={<Radio />} label="Yes" labelPlacement="start" />
                <FormControlLabel value={false} disabled control={<Radio />} label="No" labelPlacement="start" />
              </RadioGroup>
            </FormControl>
          </div>
          <div class="cont_set">
            <FormControl>
              <FormLabel><p>Valid HOA</p></FormLabel>
              <RadioGroup row value={Boolean(hoaValid)} disabled>
                <FormControlLabel value={true} disabled control={<Radio />} label="Yes" labelPlacement="start" />
                <FormControlLabel value={false} disabled control={<Radio />} label="No" labelPlacement="start" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Row>
      <Row>
        <div class="btn_box rg">
          <Button variant="contained" color="primary" disabled={!hasChanges} onClick={onSubmitHOA}>Save</Button>
          <Button color="secondary" disabled={!hasChanges} onClick={resetFromProps} class="can">Cancel</Button>
        </div>
      </Row>

    </div>
    <div class="set3_tab">
      <h3 class="hd_tg">Sub Association Information</h3>
      <SubsWrapper>
        {props.category !== HOA_CATEGORIES.MASTER && <Overlay text="Update the category to Master to see it..." />}
        <SubAssociation recordId={recordId} />
      </SubsWrapper>
    </div>
    <HoaDeleteButton name={props.name} recordId={recordId} onDelete={onDelete} />
  </Wrapper>;
}

export default HoaDetails;
