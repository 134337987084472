import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { cloneDeep, trimStart } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hoa } from "../../api/endpoints";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import Contacts from "./Tabs/Contacts";
import HoaDetails from "./Tabs/HoaDetails";
import ManagementCompany from "./Tabs/ManagementCompany";
import OrderingInfo from "./Tabs/OrderingInfo";
import SubdivisionInfo from "./Tabs/SubdivisionInfo";
//latest add
import AppContext from "../../context";
import { STATES, ROUTES } from "../../constants";
import DocumentInfo from "./Tabs/DocumentInfo";
import CreateHoa from "./Tabs/CreateHoa";
import { getCurrentDate, hoaDetailsTransformer } from "../../utils/hoa";
const { get: getHoa, getHoaDetailsByMasterId, hoaIngestion } = hoa;

const { HOA_MASTER } = ROUTES;

const TabPanelWrapper = styled.div`
  height: 100%;
`;

const StyledBox = styled(Box)`
  height: 100%;
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelWrapper role="tabpanel" hidden={value !== index} {...other}>
      <StyledBox>{children}</StyledBox>
    </TabPanelWrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
const WrapperInternal = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const tabNames = [
  "hoa-details",
  "management-company",
  "subdivision-info",
  "contacts",
  "ordering-info",
  "documents",
  "review",
  "create-hoa",
];

const Content = ({
  hoa,
  location,
  onChangeHasChanges,
  onDelete,
  onUpdate,
  show,
  showFunction,
  ItemsList,
  isReview,
  totalItems,
  subdivisionMapping,
  isStandaloneCreate,
  match
}) => {
  const [tabNum, setTabNum] = useState(0);
  const [hasChangesMgmtCompany, setHasChangesMgmtCompany] = useState(false);
  const [hasChangesHoa, setHasChangesHoa] = useState(false);
  const [ changeOnOtherTabs, setChangeOnOtherTabs] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [hoaDetails, setHoaDetails] = useState({
    HOA: {
      Assn_Category: "Stand-alone",
      Assn_Type: "HOA",
      Code: null,
      EIN: null,
      Homeowner_Authorization: "N",
      Last_Verified_Date: getCurrentDate(),
      Mailing_City: null,
      Mailing_Line1: null,
      Mailing_Line2: null,
      Mailing_State: null,
      Mailing_Zip: null,
      Mailing_Zip_4: null,
      Master_Hoa_Co_ID: null,
      Name: null,
      Notes: null,
      Payee_Type: 'HOA',
      Record_IDN: null,
      Valid_HOA: null,
      Verification_Method: null,
      Website: null
    },
    MGMT: {
      "ExtSystem.User_ID": "",
      Code: null,
      EIN: null,
      Mailing_City: null,
      Mailing_Line1: null,
      Mailing_Line2: null,
      Mailing_State: null,
      Mailing_Zip: null,
      Mailing_Zip_4: null,
      Master_Mgmt_Co_ID: null,
      Name: null,
      Notes: null,
      Record_IDN: null,
      Verified_Date: null,
      Website: null
    },
    Contacts: [],
    OrderingInfo: [],
    SubAssoc: [],
    ContactsDelete: [],
    OrderingInfoDelete: [],
    UnlinkSubAssoc: []
  });

  const history = useHistory();

  //latest add
  const [showTab, setShowTab] = useState();

  const getHoaDetails = async(recordId) => {
    if(recordId){
      try{
        const resData = await getHoaDetailsByMasterId(recordId);
        if (resData?.status === "Success") {
          const data = resData.Data;
          console.log("HOA details from property DB", data);
          const hoaObj = mapPDBDatatoInMemoryObj(data);
          setHoaDetails(hoaObj)
        } else {
          enqueueSnackbar("HOA does not exist in property DB for this Master_Assn_ID.", {
            variant: "error",
          });
        }
      }
      catch (e) {
        enqueueSnackbar("HOA does not exist in property DB for this Master_Assn_ID.", {
          variant: "error",
        });
      }
    }
  }

  const getContactDetails = (el, contactInfo) => {
    switch (el.Proc_Method) {
      case 'Phone':
        return contactInfo.Phone_Primary;
      case 'Mail':
        return contactInfo.Mailing_Address;
      case 'Email':
        return contactInfo.Email;
      case 'Fax':
        return contactInfo.Fax;
      case 'Website':
        return contactInfo.Website;
    }
  }

  const mapPDBDatatoInMemoryObj = (data) => {
    let hoaObj = {
      HOA: {
        Assn_Category: "Stand-alone",
        Assn_Type: "HOA",
        Code: null,
        EIN: null,
        Homeowner_Authorization: "N",
        Last_Verified_Date: getCurrentDate(),
        Mailing_City: null,
        Mailing_Line1: null,
        Mailing_Line2: null,
        Mailing_State: null,
        Mailing_Zip: null,
        Mailing_Zip_4: null,
        Master_Hoa_Co_ID: null,
        Name: null,
        Notes: null,
        Payee_Type: 'HOA',
        Record_IDN: null,
        Valid_HOA: null,
        Verification_Method: null,
        Website: null
      },
      MGMT: {
        "ExtSystem.User_ID": "",
        Code: null,
        EIN: null,
        Mailing_City: null,
        Mailing_Line1: null,
        Mailing_Line2: null,
        Mailing_State: null,
        Mailing_Zip: null,
        Mailing_Zip_4: null,
        Master_Mgmt_Co_ID: null,
        Name: null,
        Notes: null,
        Record_IDN: null,
        Verified_Date: null,
        Website: null
      },
      Contacts: [],
      OrderingInfo: [],
      SubAssoc: [],
      ContactsDelete: [],
      OrderingInfoDelete: [],
      UnlinkSubAssoc: []
    };
    if (data.HOA.length) {
      // Map HOA Details
      hoaObj.HOA = {
        Assn_Category: data.HOA[0].Assn_Category,
        Master_Hoa_Co_ID: data.HOA[0].Master_Hoa_Co_ID,
        Mailing_City: data.HOA[0].Mailing_City,
        Mailing_Line1: data.HOA[0].Mailing_Line1,
        Mailing_Line2: data.HOA[0].Mailing_Line2,
        Mailing_State: data.HOA[0].Mailing_State,
        Mailing_Zip: data.HOA[0].Mailing_Zip,
        Mailing_Zip_4: data.HOA[0].Mailing_Zip_4,
        Code: data.HOA[0].Code,
        Name: data.HOA[0].Name,
        Assn_Type: data.HOA[0].Assn_Type,
        EIN: data.HOA[0].EIN,
        Homeowner_Authorization: data.HOA[0].Homeowner_Authorization,
        Last_Verified_Date: data.HOA[0].Last_Verified_Date || getCurrentDate(),
        Notes: data.HOA[0].Notes,
        Payee_Type: data.HOA[0].Payee_Type || 'HOA',
        Valid_HOA: data.HOA[0].Valid_HOA,
        Validity_Period: data.HOA[0].Validity_Period,
        Verification_Method: data.HOA[0].Verification_Method,
        Website: data.HOA[0].Website,
      }
    }

    // Map Management Company
    if (data["Relation data"] && data["Relation data"].MGMT && data["Relation data"].MGMT.length && data["Relation data"].MGMT[0]["MGMT Detail"] !== "No Data") {
      const MGMTData = data["Relation data"].MGMT[0]["MGMT Detail"][0]
      hoaObj.MGMT = {
        "ExtSystem.User_ID": "",
        Code: MGMTData.Code,
        Master_Mgmt_Co_ID: MGMTData.Master_Mgmt_Co_ID || null,
        EIN: MGMTData.EIN,
        Email: MGMTData.Email,
        Mailing_City: MGMTData.Mailing_City,
        Mailing_Line1: MGMTData.Mailing_Address,
        Mailing_Line2: MGMTData.Mailing_Line2 || "",
        Mailing_State: MGMTData.Mailing_State,
        Mailing_Zip: MGMTData.Mailing_Zip,
        Mailing_Zip_4: MGMTData.Mailing_Zip_4,
        Name: MGMTData.Name,
        Notes: MGMTData.Notes,
        Phone_Primary: MGMTData.Phone_Primary,
        Record_IDN: null,
        Verified_Date: MGMTData.Verified_Date,
        Website: MGMTData.Website
      };
    }

    // Map Sub Associations
    if (data["Relation data"] && data["Relation data"].SubAssoc && data["Relation data"].SubAssoc.length && data["Relation data"].SubAssoc[0]["SubAssoc Detail"] !== "No Data") {
      const subAssocData = data["Relation data"].SubAssoc[0]["SubAssoc Detail"];
      hoaObj.SubAssoc = subAssocData;
    }

    // Map Contacts
    if (data["Relation data"] && data["Relation data"].Contacts && data["Relation data"].Contacts.length && data["Relation data"].Contacts[0]["Contacts Detail"] !== "No Data") {
      //need to filter out ordering info contacts because they will be included as well
      const contactsData = data["Relation data"].Contacts[0]["Contacts Detail"]
      hoaObj.Contacts = contactsData;
    }

    // Map Ordering Info
    if (data["Relation data"] && data["Relation data"].OrderingInfo && data["Relation data"].OrderingInfo.length && data["Relation data"].OrderingInfo[0]["Ordering Info Detail"] !== "No Data") {

      const validOrderingInfos = data["Relation data"].OrderingInfo[0]["Ordering Info Detail"].filter(function (el) {
        if (el.Proc_Org_Type !== "Other Agency") {
          //el.isInvalid = false;
          return el
        }
      });

      let tmpArr = [];
      // Compare the contacts related ordering info and fill the ordering info object with required fields
      validOrderingInfos.map(function (el) {
        //each ordering info line can have only one contact for details by definition and this is only for pull using using master id
        if ((data["Relation data"].Contacts[0]["Contacts Detail"] !== 'No Data') && Array.isArray(data["Relation data"].Contacts[0]["Contacts Detail"])) {
          var relContactList = data["Relation data"].Contacts[0]["Contacts Detail"].filter(function (revContactItem) {
            var retval = false;
            if (revContactItem.Category !== "" && revContactItem.Category !== null && el.Deliverable_Type !== "" && el.Deliverable_Type !== null && revContactItem.Category.toUpperCase() === el.Deliverable_Type.toUpperCase() && (((el.Proc_Org_Type == null || el.Proc_Org_Type == '') && (revContactItem.Object_Type === el.Owner_Org_Type && (revContactItem.Master_Object_ID === el.Master_Owner_Org_ID))) || ((el.Proc_Org_Type !== null && el.Proc_Org_Type !== '') && (revContactItem.Object_Type === el.Proc_Org_Type && (revContactItem.Master_Object_ID === el.Master_Proc_Org_ID))) || ((el.Proc_Org_Type !== null && el.Proc_Org_Type !== '') && (revContactItem.Object_Type === el.Proc_Org_Type && (el.Proc_Org_Type === el.Owner_Org_Type && revContactItem.Master_Object_ID === el.Master_Owner_Org_ID))))) {
              retval = true;
            }
            return retval;
          });
          var revisedOrdInfo = el;
          if (relContactList.length > 0) {
            //take the first match
            var contactItem = relContactList[0];
            if (relContactList.length > 1) {
              //this should not happen - since it is happening lets console log this
              console.log("More than 1 contact matches for ordering info Row for ordering info: ", el);
              console.log("contact matches are : ", relContactList);
            }
            revisedOrdInfo = {
              Master_OrdInfo_ID: el.Master_OrdInfo_ID,
              Master_Proc_Org_ID: el.Proc_Org_Type === 'Management Company' ? hoaObj.MGMT.Master_Mgmt_Co_ID : hoaObj.HOA.Master_Hoa_Co_ID,
              Master_Owner_Org_ID: el.Owner_Org_Type === 'Management Company' ? hoaObj.MGMT.Master_Mgmt_Co_ID : hoaObj.HOA.Master_Hoa_Co_ID,
              Amount: el.Amount,
              Amount_Text: null,
              Assn_Info_IDN: null,
              //adding master contact id to ordering info object so contacts can be generated for write back to prop db
              Master_Contact_ID: contactItem.Master_Contact_ID || null,
              Master_Object_ID: contactItem.Master_Object_ID || null,
              Contact_Details: getContactDetails(el, contactItem),
              Contact_Name: contactItem.Contact_Name,
              Deliverable_Type: el.Deliverable_Type,
              Notes: el.Notes,
              Notes_Text: null,
              Payment_Type: el.Payment_Type,
              Payment_Type_Text: null,
              Proc_Method: el.Proc_Method,
              Proc_Org_Name: null,
              Proc_Org_Type: el.Proc_Org_Type,
              Proc_Org_IDN: el.Master_Proc_Org_ID,
              Owner_Org_Type: el.Owner_Org_Type,
              Owner_Org_IDN: el.Master_Owner_Org_ID,
              Record_IDN: null
            };
          } else {
            //no contact found for ordering info
            console.log("ordering info added without a contact is ", revisedOrdInfo);
          }
          tmpArr.push(revisedOrdInfo);
        }
      });
      if (tmpArr.length > 0) {
        hoaObj.OrderingInfo = tmpArr;
      } 
      else {
        hoaObj.OrderingInfo = validOrderingInfos;
      }
      } 
      else {
      hoaObj.OrderingInfo = []
    }
    hoaObj.HOA.Assn_Type = hoaObj.HOA.Assn_Type ? hoaObj.HOA.Assn_Type : 'HOA';
    hoaObj.HOA.Assn_Category = hoaObj.HOA.Assn_Category ? hoaObj.HOA.Assn_Category : 'Stand-alone';
    hoaObj.HOA.Homeowner_Authorization = hoaObj.HOA.Homeowner_Authorization ? hoaObj.HOA.Homeowner_Authorization : 'N';
    if (hoaObj.HOA && !hoaObj.HOA.Payee_Type) {
      hoaObj.HOA.Payee_Type = 'HOA'
    }

    console.log('hoaDetails after mapping PDB data', hoaObj);

    return hoaObj
  }

  useEffect(async() => {
    const recordId = match.params.recordId;
    setLoading(true);
    await getHoaDetails(recordId)
    setLoading(false);
  }, [match.params.recordId])

  useEffect(() => {
    setShowTab(show);
    if (!show) {
      history.push(`${location.pathname}${location.search}#${tabNames[7]}`);
    } else {
      if (!subdivisionMapping) {
        history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
      } else {
        history.push(`${location.pathname}${location.search}#subdivision-info`);
      }
    }
  }, [show]);

  useEffect(() => {
    onChangeHasChanges(hasChangesMgmtCompany || hasChangesHoa);
  }, [hasChangesMgmtCompany, hasChangesHoa]);

  useEffect(() => {
    if (!subdivisionMapping) {
      history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
    } else {
      history.push(`${location.pathname}${location.search}#subdivision-info`);
    }
  }, [hasChangesHoa]);

  useEffect(() => {
    const tabName = trimStart(location.hash, "#");
    const tabIndex = tabNames.indexOf(tabName);
    setTabNum(tabIndex === -1 ? 0 : tabIndex);
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    if (!hasChangesMgmtCompany && !hasChangesHoa) {
      history.push(
        `${location.pathname}${location.search}#${tabNames[newValue]}`
      );
      if(tabNames[newValue] == 'hoa-details' && changeOnOtherTabs){
        getUpdatedHoa()
        setChangeOnOtherTabs(false)
      }
    } else {
      enqueueSnackbar("You need to save or cancel the changes first.", {
        variant: "error",
      });
    }
  };

  const handleChangePrimaryContactHoa = (contactId) => {
    onUpdate({
      ...hoa,
      primaryContactId: contactId,
    });
  };

  const onUpdateHoa = (details) => {
    const entity = hoaDetailsTransformer(details)
    onUpdate({
      ...entity.HOA
    });
  };

  const onUpdateMgmtCompany = (mgmtCompanyRecordId) => {
    onUpdate({
      ...hoa,
      mgmtCompanyId: mgmtCompanyRecordId,
    });
  };

  const getUpdatedHoa = async () => {
    const updatedHoa = await getHoa(hoaDetails.HOA.Master_Hoa_Co_ID)
    onUpdate(updatedHoa)
  };

  const checkForMissingIds = (data, detailRes) => {

    let details = cloneDeep(data)

    for (let i = 0; i < Object.entries(detailRes).length; i++) {
      let el = Object.entries(detailRes)[i];
      // ID not found in HOA
      if (el[0] === 'HOA' && el[1].status === 'ID Not Found') {
        console.log('HOA', el[1].status);
        console.log('Master_Hoa_Co_ID', el[1].Master_Hoa_Co_ID);

        // Clear master hoa id
        details.HOA.Master_Hoa_Co_ID = null;

        var Master_Hoa_Co_ID = el[1].Master_Hoa_Co_ID;

        // Clearing out all master ids from contacts
        details.Contacts.forEach(function (contactItem) {
          if ((contactItem.Master_Object_ID === Master_Hoa_Co_ID) && (contactItem.Object_Type == 'HOA')) {
            contactItem.Master_Contact_ID = null;
            contactItem.Master_Object_ID = null;
          }
        })

        // Clearing out all master ids from ordering infos
        details.OrderingInfo.forEach(function (ordInfoItem) {
          if ((ordInfoItem.Master_Owner_Org_ID === Master_Hoa_Co_ID && ordInfoItem.Owner_Org_Type == 'HOA') || (ordInfoItem.Master_Proc_Org_ID === Master_Hoa_Co_ID && ordInfoItem.Proc_Org_Type == 'HOA')) {
            ordInfoItem.Master_OrdInfo_ID = null;
            ordInfoItem.Master_Contact_ID = null;
            ordInfoItem.Master_Owner_Org_ID = null;
            ordInfoItem.Master_Proc_Org_ID = null;
          }
        })
      }
      // ID not found in MGMT
      if (el[0] === 'MGMT' && el[1] && el[1].status === 'ID Not Found') {
        console.log('MGMT', el[1].status);
        console.log('Master_Mgmt_Co_ID', el[1].Master_Mgmt_Co_ID);

        // Clear master hoa id
        details.MGMT.Master_Mgmt_Co_ID = null;

        var Master_Mgmt_Co_ID = el[1].Master_Mgmt_Co_ID;

        // Clearing out all master ids from contacts
        details.Contacts.forEach(function (contactItem) {
          if ((contactItem.Master_Object_ID === Master_Mgmt_Co_ID) && (contactItem.Object_Type == 'Management Company')) {
            contactItem.Master_Contact_ID = null;
            contactItem.Master_Object_ID = null;
          }
        })

        // Clearing out all master ids from ordering infos
        details.OrderingInfo.forEach(function (ordInfoItem) {
          if ((ordInfoItem.Master_Owner_Org_ID === Master_Mgmt_Co_ID && ordInfoItem.Owner_Org_Type == 'Management Company') || (ordInfoItem.Master_Proc_Org_ID === Master_Mgmt_Co_ID && ordInfoItem.Proc_Org_Type == 'Management Company')) {
            ordInfoItem.Master_OrdInfo_ID = null;
            ordInfoItem.Master_Contact_ID = null;
            ordInfoItem.Master_Owner_Org_ID = null;
            ordInfoItem.Master_Proc_Org_ID = null;
          }
        })
      }
      // ID not found in Contacts
      if (el[0] === 'Contacts' && el[1].length) {
        el[1].map(function (elem, index) {
          if (elem.status === 'ID Not Found') {
            // Clearing out all master ids from standalone contacts
            details.Contacts.map(function (contactItem) {
              if (contactItem.Master_Contact_ID === elem.Master_Contact_ID) {
                contactItem.Master_Contact_ID = null;
              }
            })
            // Clearing out all master ids from ordering info contacts
            details.OrderingInfo.map(function (ordInfoItem) {
              if (ordInfoItem.Master_Contact_ID === elem.Master_Contact_ID) {
                ordInfoItem.Master_Contact_ID = null;
              }
            })
            console.log('Contacts', elem.status);
            console.log('contacts after clear ids', details.Contacts)
          }
        });
      }
      // ID not found in Ordering Info
      if (el[0] === 'OrderingInfo' && el[1].length) {
        el[1].map(function (elem) {
          if (elem.status === 'ID Not Found') {
            // Clearing out all master ids from ordering infos
            details.OrderingInfo.map(function (oiItem) {
              if (oiItem.Master_OrdInfo_ID == elem.Master_OrdInfo_ID) {
                oiItem.Master_OrdInfo_ID = null;
              }
            })
            console.log('OrderingInfo', elem.status);
            console.log('orderin info after clear ids', details.OrderingInfo)
          }
        });
      }
      // ID not found in Sub Associations
      if (el[0] === 'SubAssoc' && el[1].length) {
        el[1].map(function (elem, index) {
          //why should this even happen - we pulled the list from prop DB for every call!
          if (elem.status === 'ID Not Found') {
            console.log('SubAssoc', elem.status);
            const subAssoc = Object.values(details.SubAssoc);
            if (subAssoc.length > 0) {
              details.SubAssoc[subAssoc[index].Master_Hoa_Co_ID].Master_Hoa_Co_ID = null;
            }
            console.log('sub ass', details.SubAssoc);
          }
        });
      }
      // ID not found in Contacts Delete
      if (el[0] === 'ContactsDelete' && el[1].length) {
        el[1].map(function (elem) {
          if (elem.status === 'ID Not Found') {
            // Clearing out all master ids from contacts delete list
            details.ContactsDelete = []
          }
        });
      }
      // ID not found in Ordering Info Delete
      if (el[0] === 'OrderingInfoDelete' && el[1].length) {
        el[1].map(function (elem) {
          if (elem.status === 'ID Not Found') {
            // Clearing out all master ids from ordering info delete list
            details.OrderingInfoDelete = [];
          }
        });
      }

    }

    console.log('Final object after clear all master ids that are not found', details);
    // Resubmit HOA to propDB after clear all master ids
    return details
  }

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // setHoaDetails(data);
      console.log('submitObj', data)
      let PDBResponse = {};
      let recordId = null;

      PDBResponse = await hoaIngestion(data);
      console.log('PDB RES', PDBResponse);

      if(PDBResponse?.data?.status === "success" && PDBResponse?.data?.output?.HOA?.Master_Hoa_Co_ID){
        recordId = PDBResponse?.data?.output?.HOA?.Master_Hoa_Co_ID; 
      }

      // Handling duplicates error
      if (PDBResponse.data.status === "Failed: Duplicate Record Found") {
        console.log('Conflicting or duplicate data has been found in the system', PDBResponse)
        throw {
          response: {
            data: {
              message: "Conflicting or duplicate data has been found in the system you are attempting to create"
            }
          }
        }
      }

      // Handling missing incoming fields error
      if (PDBResponse.message === "success" && typeof PDBResponse.data === "string") {
        throw {
          response: {
            data: {
              message: PDBResponse.data
            }
          }
        }
      }

      // Handling the missing id error
      if(PDBResponse?.data?.status && (PDBResponse?.data?.status === "Failed:  Record not Found" || PDBResponse?.data?.status.indexOf('Record not Found') !== -1 )){
        console.log('Failed:  Record not Found')
        const detailRes = PDBResponse.data.Detail;
        const resubmitDataObj = checkForMissingIds(data, detailRes);
        console.log('Resubmit Data Obj', resubmitDataObj);
        PDBResponse = await hoaIngestion(resubmitDataObj);
        recordId = PDBResponse?.data?.output?.HOA?.Master_Hoa_Co_ID;
        if(recordId){
          history.push(`${HOA_MASTER}/${recordId}${location.search}${location.hash}`);
        }
        setLoading(false)
      }

      await getHoaDetails(recordId)
      enqueueSnackbar('The HOA has been updated', { variant: 'success' });
    } catch (e) {
      console.log('error', e)
      const message = e.response.data.message;
      enqueueSnackbar(`Cannot update the HOA: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoading(false);
  }

  if (!match.params.recordId && !isStandaloneCreate) {
    return (
      <>
        Please select an HOA{" "}
        {totalItems ? `out of the ${totalItems} HOAs found` : ""}{" "}
      </>
    );
  }
  if (isStandaloneCreate) {
    return <></>;
  }

  const transformedDetails = hoaDetailsTransformer(hoaDetails)

  console.log('transformed data', transformedDetails)

  return (
    <div class="wid_dep">
      <AppContext.Provider value={{
        hoaDetails,
        transformedDetails,
        onSubmit
      }}>
        <Wrapper>
          <WrapperInternal>
            <AppBar position="static">
              {tabNum == 0 || tabNum == 7 ? (
                ""
              ) : (
                <span
                  style={{ color: "black", marginTop: -12, marginBottom: 10 }}
                >
                  HOA Name: {transformedDetails.HOA.name}
                </span>
              )}
              <div class="tab_sty">
                <Tabs value={tabNum} onChange={handleChange}>
                  {/* latest add */}
                  {showTab ? <Tab label="Hoa Details" /> : null}
                  {showTab ? <Tab label="Management Company" /> : null}
                  {showTab ? <Tab label="Subdivision Info" /> : null}
                  {showTab ? <Tab label="Contacts" /> : null}
                  {showTab ? <Tab label="Ordering Info" /> : null}
                  {showTab ? <Tab label="Documents" /> : null}
                  {showTab && isReview ? <Tab label="Review" /> : null}
                  {!showTab ? <Tab disabled={true} label="Create HOA" /> : null}
                </Tabs>
              </div>
            </AppBar>
            <ContentWrapper>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={0}
              >
                <HoaDetails
                  {...transformedDetails.HOA}
                  loading={loading}
                  onChangeHasChanges={setHasChangesHoa}
                  onDelete={onDelete}
                  onUpdate={onUpdateHoa}
                />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={1}
              >
                <ManagementCompany
                  hoaRecordId={transformedDetails.HOA.recordId}
                  mgmtCompanyRecordId={transformedDetails.MGMT.recordId}
                  onChangeHasChanges={setHasChangesMgmtCompany}
                  onUpdate={onUpdateMgmtCompany}
                  setChangeOnOtherTab={setChangeOnOtherTabs}
                  getHoaDetails={getHoaDetails}
                />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={2}
              >
                <SubdivisionInfo
                  hoaRecordId={transformedDetails.HOA.recordId}
                  hoaState={transformedDetails.HOA.state}
                />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={3}
              >
                <Contacts
                  contacts={transformedDetails.Contacts}
                  hoaPrimaryContactId={transformedDetails.HOA.primaryContactId}
                  hoaRecordId={transformedDetails.HOA.recordId}
                  mgmtCompanyRecordId={transformedDetails.HOA.mgmtCompanyId}
                  onChangePrimaryHoa={handleChangePrimaryContactHoa}
                  onCreate={setChangeOnOtherTabs}
                />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={4}
              >
                <OrderingInfo
                  orderingInfo={transformedDetails.OrderingInfo}
                  hoaRecordId={transformedDetails.HOA.recordId}
                  mgmtCompanyRecordId={transformedDetails.HOA.mgmtCompanyId}
                />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={5}
              >
                <DocumentInfo hoaRecordId={transformedDetails.HOA.recordId} />
              </TabPanel>
              <TabPanel
                style={{ display: !isReview ? "none" : null }}
                value={tabNum}
                index={6}
              >
                <CreateHoa
                  {...hoa}
                  onChangeHasChanges={setHasChangesHoa}
                  onDelete={onDelete}
                  onUpdate={onUpdate}
                  changeShow={(res) => showFunction(res)}
                  hoaItemsList={(res) => ItemsList(res)}
                />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? "none" : null }}
                value={tabNum}
                index={7}
              >
                <CreateHoa
                  {...hoa}
                  onChangeHasChanges={setHasChangesHoa}
                  onDelete={onDelete}
                  onUpdate={onUpdate}
                  changeShow={(res) => showFunction(res)}
                  hoaItemsList={(res) => ItemsList(res)}
                />
              </TabPanel>
            </ContentWrapper>
          </WrapperInternal>
        </Wrapper>
      </AppContext.Provider>
    </div>
  );
};

export default withRouter(Content);
