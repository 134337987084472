import { 
  HOA_CATEGORIES, 
  HOA_CATEGORY_DEFAULT, 
  HOA_TYPE_DEFAULT, 
  HOA_TYPES, 
  STATES, 
} from "../constants";

export function getCurrentDate() {
  var d = new Date(),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export const generateFullAddress = (line1, line2, city, state, zip) => {
  let fullAddress = (line1 ?? '') + (line2 ? ` ${line2}` : '') + (line1 || line2 ? ', ' : '') + (city ? `${city}, ` : '') + state + (zip ? `, ${zip}` : '');
  return fullAddress
}

export const hoaDetailsTransformer = (data) => {
  let transformedHOA = {};

  transformedHOA.HOA = transformHOA(data);
  transformedHOA.MGMT = transformMGMT(data);
  transformedHOA.Contacts = transformContact(data);
  transformedHOA.OrderingInfo = transformOrderingInfo(data);
  transformedHOA.SubAssoc = transformSubAssociation(data);

  return transformedHOA
}

export const mgmtDetailsTransformer = (data) => {
  let transformedMgmt = {};

  transformedMgmt.MGMT = transformMGMT(data);
  transformedMgmt.Contacts = transformContact(data);
  transformedMgmt.OrderingInfo = transformOrderingInfo(data);

  return transformedMgmt
}

export const transformHOA = (data) => {
  const hoa = data.HOA;
  let primaryContact = null;
  let primaryContactId = null;
  let mgmtCompanyId = null;

  if (data?.Contacts?.length) {
    primaryContact = data.Contacts.filter(function(el){
      if((el.Category === "" || el.Category === null) && el.Priority === 'Y' && el.Object_Type === 'HOA'){
          return el;
      }
    });
  }

  if (primaryContact?.length) {
    primaryContactId = primaryContact[0].Master_Contact_ID;
  }

  if(data?.MGMT?.Master_Mgmt_Co_ID){
    mgmtCompanyId = data.MGMT.Master_Mgmt_Co_ID;
  }

  return {
    address1: hoa.Mailing_Line1,
    address2: hoa.Mailing_Line2,
    addressFull: generateFullAddress(hoa.Mailing_Line1, hoa.Mailing_Line2, hoa.Mailing_City, hoa.Mailing_State, hoa.Mailing_Zip),
    authorization: hoa.Homeowner_Authorization === 'Y' ? true : false ,
    category: Object.values(HOA_CATEGORIES).includes(hoa.Assn_Category) ? hoa.Assn_Category : HOA_CATEGORY_DEFAULT,
    city: hoa.Mailing_City,
    code: hoa.Code,
    ein: hoa.EIN,
    isValid: hoa.Valid_HOA === 'Y' ? true : false,
    mgmtCompanyId: mgmtCompanyId ? Number(mgmtCompanyId): null,
    name: hoa.Name,
    notes: hoa.Notes,
    updatedDate: hoa.Last_Verified_Date,
    masterId: null,
    primaryContactId: primaryContactId,
    recordId: Number(hoa.Master_Hoa_Co_ID),
    state: STATES.find(state => state.code === hoa.Mailing_State) ? hoa.Mailing_State : null,
    type: Object.values(HOA_TYPES).includes(hoa.Assn_Type) ? hoa.Assn_Type : HOA_TYPE_DEFAULT,
    website: hoa.Website,
    zip: hoa.Mailing_Zip,
    zip4: hoa.Mailing_Zip_4,
  }
}

export const transformMGMT = (data) => {
  const mgmt = data.MGMT;
  let primaryContact = null;
  let primaryContactId = null;

  if (data?.Contacts?.length) {
    primaryContact = data.Contacts.filter(function(el){
      if((el.Category === "" || el.Category === null) && el.Priority === 'Y' && el.Object_Type === 'Management Company'){
          return el;
      }
    });
  }

  if (primaryContact?.length) {
    primaryContactId = primaryContact[0].Master_Contact_ID;
  }

  return {
    address: mgmt.Mailing_Line1,
    city: mgmt.Mailing_City,
    code: mgmt.Code,
    ein:mgmt.EIN,
    name: mgmt.Name,
    notes:mgmt.Notes,
    primaryContactId: primaryContactId,
    recordId: Number(mgmt.Master_Mgmt_Co_ID),
    state: STATES.find(state => state.code === mgmt.Mailing_State) ? mgmt.Mailing_State : null,
    website: mgmt.Website,
    updatedDate: mgmt.Verified_Date,
    zip: mgmt.Mailing_Zip,
    zip4: mgmt.Mailing_Zip_4,
    zip4: mgmt.Mailing_Zip_4,
    zip4: mgmt.Mailing_Zip_4,
  }
}

export const transformContact = (data) => {
  const contacts = data.Contacts.map(item => {
    return {
      address: item.Mailing_Address,
      email: item.Email,
      fax: item.Fax,
      name: item.Contact_Name,
      phonePrimary: item.Phone_Primary,
      phoneSecondary: item.Phone_Secondary,
      recordId: Number(item.Master_Contact_ID),
      title: item.Contact_Title,
      type: item.Object_Type === 'HOA' ? 'hoa' : 'mgmtCompany',
      website: item.Website,
      objectId: item.Master_Object_ID,
      category: item.Category,
      priority: item.Priority
    }
  });
    
  return contacts
}

export const transformSubAssociation = (data) => {
  const subAssoc = data.SubAssoc.map(hoa => {
    return {
      address1: hoa.Mailing_Line1,
      address2: hoa.Mailing_Line2,
      addressFull: generateFullAddress(hoa.Mailing_Line1, hoa.Mailing_Line2, hoa.Mailing_City, hoa.Mailing_State, hoa.Mailing_Zip),
      authorization: hoa.Homeowner_Authorization === 'Y' ? true : false ,
      category: Object.values(HOA_CATEGORIES).includes(hoa.Assn_Category) ? hoa.Assn_Category : HOA_CATEGORY_DEFAULT,
      city: hoa.Mailing_City,
      code: hoa.Code,
      ein: hoa.EIN,
      isValid: hoa.Valid_HOA === 'Y' ? true : false,
      name: hoa.Name,
      notes: hoa.Notes,
      masterId: data.HOA.Master_Hoa_Co_ID,
      recordId: Number(hoa.Master_Hoa_Co_ID),
      id: Number(hoa.Master_Hoa_Co_ID),
      state: STATES.find(state => state.code === hoa.Mailing_State) ? hoa.Mailing_State : null,
      type: Object.values(HOA_TYPES).includes(hoa.Assn_Type) ? hoa.Assn_Type : HOA_TYPE_DEFAULT,
      website: hoa.Website,
      zip: hoa.Mailing_Zip,
      zip4: hoa.Mailing_Zip_4,
    }
  });
    
  return subAssoc
}

export const transformOrderingInfo = (data) => {
  const orderingInfos = data.OrderingInfo.map(el => {
    let contactItem = {};
    const orderingInfoContact = data.Contacts.filter(item => {
      if(item.Category !== "" && item.Category !== null && el.Deliverable_Type !== "" && el.Deliverable_Type !== null && item.Category.toUpperCase() === el.Deliverable_Type.toUpperCase() && (((el.Proc_Org_Type==null || el.Proc_Org_Type=='') && (item.Object_Type === el.Owner_Org_Type && (item.Master_Object_ID === el.Master_Owner_Org_ID))) || ((el.Proc_Org_Type!==null && el.Proc_Org_Type!=='') && (item.Object_Type === el.Proc_Org_Type && (item.Master_Object_ID === el.Master_Proc_Org_ID))) || ((el.Proc_Org_Type!==null && el.Proc_Org_Type!=='') && (item.Object_Type === el.Proc_Org_Type && (el.Proc_Org_Type === el.Owner_Org_Type && item.Master_Object_ID === el.Master_Owner_Org_ID))) )){
        return true
      }
    });

    if(orderingInfoContact.length){
      contactItem = orderingInfoContact[0]
    }

    return {
      recordId: Number(el.Master_OrdInfo_ID),
      address: contactItem.Mailing_Address,
      deliveryType: el.Deliverable_Type,
      email: contactItem.Email || "",
      fax: contactItem.Fax || "",
      name: contactItem.Contact_Name || "",
      phonePrimary: contactItem.Phone_Primary || "",
      phoneSecondary: contactItem.Phone_Secondary || "",
      processMethod: el.Proc_Method,
      type: el.Owner_Org_Type === 'HOA' ? 'hoa' : 'mgmtCompany',
      notes: el.Notes,
      website: contactItem.Website || "",
    }
  });

  return orderingInfos
}