import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { FIPS, COLUMN_WIDTH } from '../../constants';
import SubdivisionOtherHoas from "../../containers/SubdivisionOtherHoas";
import GridCellExpand from "../GridCellExpand"
import ActionButtons from "./ActionButtons";
import { useSnackbar } from "notistack";
import PropTypes from 'prop-types';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const div_div = {
    height: '500px',
}

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const Subdivisions = ({ items, ofHoa, onDisconnect }) => {
    function renderCellExpand(params) {
        const county = FIPS[params?.row?.fips] ? FIPS[params?.row?.fips].county : 'INCORRECT FIPS CODE';
        return (
            <GridCellExpand value={params.value || county} width={params.colDef.computedWidth} />
        );
    }
    renderCellExpand.propTypes = {
        colDef: PropTypes.object.isRequired,
        value: PropTypes.string.isRequired,
    };
    const columns = [
        {
            field: 'name',
            headerName: 'Subdivision Name',
            renderCell: ({ row: { name } }) => <GridCellExpand value={name} width={192} />,
            minWidth: COLUMN_WIDTH.NAME,
            flex: 1,
            filterable: false,
        },
        {
            field: 'normalizedName',
            headerName: 'Normalized Name',
            renderCell: ({ row: { normalizedName } }) => <GridCellExpand value={normalizedName} width={191} />,
            minWidth: COLUMN_WIDTH.NAME,
            flex: 1,
            filterable: false,
        },
        {
            field: 'fips',
            headerName: 'FIPS',
            minWidth: COLUMN_WIDTH.FIPCD,
            filterable: false,
            flex: 1
        },
        {
            field: 'county',
            headerName: 'County',
            renderCell: renderCellExpand,
            minWidth: COLUMN_WIDTH.COUNTY,
            filterable: false,
            flex: 1
        },
        {
            field: 'state',
            headerName: 'State',
            renderCell: ({ row: { fips } }) => FIPS[fips] ? FIPS[fips].state : 'INCORRECT FIPS CODE',
            minWidth: COLUMN_WIDTH.STATE,
            filterable: false,
            flex: 1
        },
        {
            field: 'hasFee',
            headerName: 'Has Fees',
            renderCell: ({ row: { hasFee } }) => hasFee ? 'Yes' : 'No',
            minWidth: COLUMN_WIDTH.HASFEE,
            filterable: false,
            flex: 1
        },
        {
            field: 'otherHoas',
            headerName: 'Other HOAs',
            renderCell: ({ row: { recordId } }) => <SubdivisionOtherHoas subdivisionId={recordId} />,
            minWidth: COLUMN_WIDTH.OTHER,
            filterable: false,
            flex: 1
        },
        {
            field: 'action',
            headerName: 'Action',
            renderCell: ({ row: { recordId } }) => <ActionButtons hoaId={ofHoa} onDisconnect={handleOnDisconnect(recordId)} subdivisionId={recordId} />,
            minWidth: COLUMN_WIDTH.ACTION,
            filterable: false,
            flex: 1
        },
    ];

    const handleOnDisconnect = (recordId) => () => {
        onDisconnect(recordId);
        enqueueSnackbar('Subdivision has been disconnected successfully', { variant: 'success' });
    }

    const { enqueueSnackbar } = useSnackbar();

    return <Wrapper>
        <div class="set4_tab">
            <HeaderWrapper>
                <h3 class="hd_tg">Subdivision Information</h3>
            </HeaderWrapper>
            <div class="tab_dev">
                <div style={{ height: '320px', width: '100%' }}>
                    <div style={{ height: '300px', width: '100%' }} class="div_row">
                        <DataGrid
                            rows={items.map((record) => ({ id: record.recordId, ...record }))}
                            columns={columns}
                            rowsPerPageOptions={[5]}
                        />
                    </div>
                </div>
            </div>
        </div>
    </Wrapper>
}

export default Subdivisions;
