export function getBounds(map) {
  return {
    ne: {
      lat: map.getBounds().getNorthEast().lat(),
      lng: map.getBounds().getNorthEast().lng(),
    },
    nw: {
      lat: map.getBounds().getNorthEast().lat(),
      lng: map.getBounds().getSouthWest().lng(),
    },
    se: {
      lat: map.getBounds().getSouthWest().lat(),
      lng: map.getBounds().getNorthEast().lng(),
    },
    sw: {
      lat: map.getBounds().getSouthWest().lat(),
      lng: map.getBounds().getSouthWest().lng(),
    },
  };
}

export function getNewPolygon(maps, coordinates) {
  return new maps.Polygon({
    paths: coordinates,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.2,
    editable: true,
    draggable: true,
  });
}
export function getDrawingManager(maps) {
  return new maps.drawing.DrawingManager({
    drawingMode: maps.drawing.OverlayType.POLYGON,
    drawingControl: true,
    drawingControlOptions: {
      position: maps.ControlPosition.TOP_CENTER,
      drawingModes: [maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions: {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.2,
      editable: true,
      draggable: true,
    },
    polylineOptions: {
      geodesic: true,
      strokeOpacity: 1,
      strokeColor: "black",
    },
  });
}
