import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { hoa, mgmtCompany } from "../api/endpoints";
import ContactAddEditButton from "../components/ContactAddEditButton";
import ContactDeleteButton from "../components/ContactDeleteButton";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import AppContext from '../context';
import {cloneDeep} from 'lodash';
import Overlay from "../components/Overlay";
import { COLUMN_WIDTH, CONTACT_TYPES } from "../constants";
import GridCellExpand from "../components/GridCellExpand";
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const IsPrimaryCell = styled.div`
  position: relative;
  width: 100%;
`;

const Contacts = ({contacts, className, onChangePrimary, primaryContactId, recordId, type, onCreate }) => {
  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }
  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };
  const columns = [
    {
      field: 'isPrimary',
      headerName: 'Primary',
      renderCell: ({ row }) => {
        let content;
        if (row.priority === 'Y') {
          content = 'Yes';
        } else {
          content = <Button onClick={setPrimary(row.recordId)} variant="outlined">Set</Button>;
        }
        return <IsPrimaryCell>
          {loadingPrimary && <LoadingOverlay />}
          {content}
        </IsPrimaryCell>
      },
      filterable: false,
      minWidth: 130,
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Contact Name',
      minWidth: COLUMN_WIDTH.NAME,
      filterable: false,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: COLUMN_WIDTH.TITLE,
      filterable: false,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      field: 'phonePrimary',
      headerName: 'Primary Phone',
      filterable: false,
      minWidth: COLUMN_WIDTH.PRIMARY_PHONE,
      flex: 1
    },
    {
      field: 'phoneSecondary',
      headerName: 'Secondary Phone',
      filterable: false,
      minWidth: COLUMN_WIDTH.SECONDARY_PHONE,
      flex: 1
    },
    {
      field: 'fax',
      headerName: 'Fax',
      filterable: false,
      minWidth: COLUMN_WIDTH.FAX,
      flex: 1
    },
    {
      field: 'address',
      headerName: 'Mailing address',
      minWidth: COLUMN_WIDTH.ADDRESS,
      filterable: false,
      renderCell: renderCellExpand,
      flex:1
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: COLUMN_WIDTH.EMAIL,
      flex: 1,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'website',
      headerName: 'Website',
      minWidth: COLUMN_WIDTH.WEBSITE,
      flex: 1,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row }) => <ActionCell>
        <ContactAddEditButton entity={row} linkedRecordId={recordId} onEdit={onEdit} type={type} />
        <ContactDeleteButton
          contacts={contacts}
          entity={row}
          contactId={row.recordId}
          linkedRecordId={recordId}
          onDelete={onDelete(row.recordId, recordId)}
          type={type}
        />
      </ActionCell>,
      filterable: false,
      minWidth: COLUMN_WIDTH.ACTION,
      flex: 1,
    },
  ];

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPrimary, setLoadingPrimary] = useState(false);

  //Context
  const { hoaDetails, onSubmit, transformedDetails } = useContext(AppContext);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!recordId || !type) {
      // setItems([]);
      return;
    }
  }, [recordId, type]);

  useEffect(() => {
    if (contacts.length !== 1) {
      return;
    }

    // if(contacts[0].priority === 'N'){
    //   (async () => {
    //     setLoadingPrimary(true);
    //     let linkedEntity;
    //     try {
    //       if (type === CONTACT_TYPES.hoa) {
    //         linkedEntity = await hoaPatch(recordId, { primaryContactId: contacts[0].recordId });
    //       } else {
    //         linkedEntity = await mgmtCompanyPatch(recordId, { primaryContactId: contacts[0].recordId });
    //       }
    //       if (linkedEntity.primaryContactId === contacts[0].recordId) {
    //         onChangePrimary(contacts[0].recordId);
    //       }
    //     }
    //     catch (e) { }
    //     setLoadingPrimary(false);
    //   })();
    // }

  }, [contacts])

  const setPrimary = contactId => async () => {
    try {
      setLoadingPrimary(true);

      let hoaCreationData = cloneDeep(hoaDetails);

      const contactIds = contacts.map(item => item.recordId);
      
      // Set priority to 'Y' of the selected contact and 'N' of the other contacts
      hoaCreationData.Contacts.map(item => {
        const itemId = Number(item.Master_Contact_ID);
        if(contactIds.includes(itemId)){
          if(itemId === contactId){
            item.Priority = 'Y'
          }
          else{
            item.Priority = 'N'
          }
        }
      })

      console.log('hoaCreationData', hoaCreationData)

      await onSubmit(hoaCreationData)

      onChangePrimary(contactId);
      enqueueSnackbar('The primary contact has been changed.', { variant: 'success' });
      // onCreate(true);
      setLoadingPrimary(false);
    } catch (e) {
      setLoadingPrimary(false);
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while setting the contact to primary: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
  }

  const onDelete = (contactId, linkedRecordId) => () => {
    // onCreate(true)
  }

  const onAdd = async (entity) => {
    // onCreate(true);
  }

  const onEdit = (entity) => {
    // onCreate(true)
  }

  return <Wrapper className={className}>
    <div class="set4_tab">
      {loading && <LoadingOverlay />}
      {!recordId && <Overlay text={`The ${type === CONTACT_TYPES.hoa ? 'HOA' : 'Management Company'} is not set.`} />}
      <div class="wte_down">
        <HeaderWrapper>
          <h3 class="hd_tg">{type === CONTACT_TYPES.hoa ? 'HOA' : 'Management Company'} Contacts</h3>
          <ContactAddEditButton onAdd={onAdd} linkedRecordId={recordId} type={type} />
        </HeaderWrapper>
        <div style={{ height: '400px', width: '100%' }}>
          {/* items.map - brute fix to keep the ID for the DataGrid component. */}
          <DataGrid rows={contacts.map((record) => ({ id: record.recordId, ...record }))} columns={columns} rowsPerPageOptions={[]} />
        </div>
      </div>
    </div>
  </Wrapper>;
}

export default Contacts;
