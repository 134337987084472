import React, { useEffect, useState } from "react";
import { getKeys } from "../../api/endpoints/property";
import { useSnackbar } from "notistack";
import styled from "styled-components";

import "./Legend.css";
const Wrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  height: 150px !important;
  width: 100%
  background-color: #fff !important;
  // padding: 10px 10px !important;
  // border: 1px solid;
  // margin-top: 10px;
`;

function Legend({ view, viewPort }) {
  const [keys, setKeys] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (view.viewName !== "Default") {
      const request = getKeys(view.id);
      (async () => {
        try {
          const tempKeys = await request;
          setKeys(tempKeys.items);
        } catch (e) {
          enqueueSnackbar("Could not load Keys legend ", { variant: "error" });
        }
      })();
    } else if (view.viewName === "Default") {
      setKeys([
        {
          id: "0",
          viewIdn: "0",
          keyColor: "#5491f5",
          keyText: `Properties in the Subdivision`,
        },
      ]);
    }
  }, [view]);

  useEffect(() => {
    if (viewPort) {
      setKeys([
        ...keys,
        {
          id: "0",
          viewIdn: "0",
          keyColor: "red",
          keyText: `Properties in ViewPort`,
        },
      ]);
    } else {
      const tempKeys = keys.filter((key) => {
        if (key.keyText != "Properties in ViewPort") return key;
      });
      setKeys(tempKeys);
    }
  }, [viewPort]);

  return (
    <div style={{ width: "30%" }}>
      {keys.length > 0 ? (
        <Wrapper className="wht_box">
          <h4 className="hd_box">Legend</h4>
          {keys.map((key, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                  marginTop: "5px",
                }}
              >
                <div
                  className="circle"
                  style={{ backgroundColor: `${key.keyColor}` }}
                ></div>
                <div className="keyText"> {` - ${key.keyText}`}</div>
              </div>
            );
          })}
        </Wrapper>
      ) : (
        ""
      )}
    </div>
  );
}

export default Legend;
