import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import Filter from "./Filter";
import ZipFilter from "./ZipFilter";
import Map from "./Map";
import { property } from "../../api/endpoints";
import { useSnackbar } from "notistack";
import {
  getBounds,
  getNewPolygon,
  getDrawingManager,
} from "../../utils/mapUtils";
import Legend from "../../components/Legend";
import Actions from "./Actions";
const {
  getPropertiesBySubdivision,
  getPropertiesByZip,
  getPropertiesInViewPort,
  getViewProperties,
  postAction,
} = property;
const Wrapper = styled.div`
  display: flex !important;
  height: 100% !important;
  background-color: #f0f7ff !important;
  padding: 22px 0px !important;
`;
const LeftBar = styled.div`
  flex-basis: 30% !important;
  width: 30%;
  display: flex !important;
  flex-direction: column !important;
  // overflow: hidden !important;
  position: relative !important;
`;
const RightBar = styled.div`
  flex-basis: 70% !important;
  overflow: auto;
  width: 70%;
  background-color: rgb(235, 243, 252) !important;
`;

export default function VisualMap() {
  const defaultProps = {
    center: {
      lat: 39.5,
      lng: -98.35,
    },
    zoom: 4,
  };
  const [loading, setLoading] = useState(false);
  const [leftSidebar, setLeftSidebar] = useState(true);
  const [filter, setFilter] = useState({});
  const [zipFilter, setZipFilter] = useState({});
  const [resetFilter, setResetFilter] = useState(false);
  const [items, setItems] = useState([]);
  const [viewPortItems, setViewPortItems] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [totalItemsZip, setTotalItemsZip] = useState();
  const [viewPort, setViewPort] = useState(false);
  const [center, setCenter] = useState(defaultProps.center);
  const [zoom, setZoom] = useState(defaultProps.zoom);
  const [currentBounds, setCurrentBounds] = useState();
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [polygon, setPolygon] = useState();
  const [drawingManager, setDrawingManager] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState({
    id: 0,
    viewName: "Default",
  });
  useEffect(() => {
    if (!filter.name && !filter.state && !filter.fips) {
      setItems([]);
      return;
    }
    setLoading(true);
    const request = getPropertiesBySubdivision(filter.name, filter.fips);
    (async () => {
      try {
        const properties = await request;
        setItems(properties.items);
        setTotalItems(properties?.meta?.totalItems);
        setTotalItemsZip(null);
        let tempCenter = {
          lat: parseFloat(properties.items[0].lat),
          lng: parseFloat(properties.items[0].long),
        };
        setZoom(16);
        setCenter(tempCenter);
      } catch (e) {
        enqueueSnackbar(
          "Could not load properties! Please try again or narrow the search. ",
          { variant: "error" }
        );
      }
      setLoading(false);
    })();
    return () => {
      request.cancel();
      setLoading(false);
    };
  }, [filter]);
  useEffect(() => {
    if (!viewPort) {
      setViewPortItems([]);
      return;
    }
    setLoading(true);
    const body = {
      bounds: currentBounds,
      name: filter?.name,
      fipscd: filter?.fips,
      state: filter?.state,
    };
    const request = getPropertiesInViewPort(body);
    (async () => {
      try {
        const properties = await request;
        let allItems = properties.items;
        let onlyViewPortItems = [];
        onlyViewPortItems = allItems.filter((el) => {
          return !items.find((element) => {
            return element.id == el.id;
          });
        });
        setViewPortItems(onlyViewPortItems);
      } catch (e) {
        enqueueSnackbar("Could not load properties in the viewport. ", {
          variant: "error",
        });
      }
      setLoading(false);
    })();
    return () => {
      request.cancel();
      setLoading(false);
    };
  }, [viewPort]);
  const onChangeFilter = (filter) => {
    setFilter(filter);
  };
  const onToggleViewPort = (viewPort) => {
    setViewPort(viewPort);
  };
  const onChangeZipFilter = (filter) => {
    setZipFilter(filter);
  };
  const onChangeView = (view) => {
    setView(view);
    if (view.viewName == "Default") {
      setFilter([]);
      setItems([]);
      setViewPortItems([]);
      setTotalItems();
      polygon?.setMap();
      setResetFilter(true);
      return;
    }
    setLoading(true);
    if (view.viewName != "Default") {
      const tempBounds = {
        ne: { lat: parseFloat(view.neLat), lng: parseFloat(view.neLng) },
        sw: { lat: parseFloat(view.swLat), lng: parseFloat(view.swLng) },
      };
      const tempCenter = new maps.LatLngBounds(
        tempBounds.sw,
        tempBounds.ne
      ).getCenter();
      const request = getViewProperties(view.id);
      (async () => {
        try {
          const properties = await request;
          let items = properties.items;
          setItems(items); 
          setZoom(view.zoom);
          setCenter({ lat: tempCenter.lat(), lng: tempCenter.lng() });
        } catch (e) {
          enqueueSnackbar("Could not load properties in the view!", {
            variant: "error",
          });
        }
        setLoading(false);
      })();
      return () => {
        request.cancel();
        setLoading(false);
      };
    }
  };
  useEffect(() => {
    if (!filter.zip) {
      setItems([]);
      return;
    }
    setLoading(true);
    const request = getPropertiesByZip(filter.zip);
    (async () => {
      try {
        const properties = await request;
        setItems(properties.items);
        setTotalItems(null);
        setTotalItemsZip(properties.meta.totalItems);
        if (properties.meta.totalItems > 1000) {
          enqueueSnackbar(
            "More than thousand properties found! Only displaying 1000 ",
            { variant: "warning" }
          );
        }
        let tempCenter = {
          lat: parseFloat(properties.items[0].lat),
          lng: parseFloat(properties.items[0].long),
        };
        setZoom(13);
        setCenter(tempCenter);
      } catch (e) {
        enqueueSnackbar(
          `Could not load properties! Please try again or narrow the search. ${e}`,
          { variant: "error" }
        );
      }
      setLoading(false);
    })();
    return () => {
      request.cancel();
      setLoading(false);
    };
  }, [zipFilter]);
  const handleViewSidebar = () => {
    setLeftSidebar(!leftSidebar);
  };
  const displayPolygon = (display) => {
    if (display) {
      const tempDrawingManager = getDrawingManager(maps);
      setDrawingManager(tempDrawingManager);
      tempDrawingManager.setMap(map);
      maps.event.addListener(
        tempDrawingManager,
        "polygoncomplete",
        function (newPolygon) {
          setPolygon(newPolygon);
          maps.event.addDomListener(document, "keyup", function (e) {
            var code = e.keyCode ? e.keyCode : e.which;
            if (code === 46) {
              newPolygon.setMap(null);
              tempDrawingManager.setMap(map);
            }
          });
          tempDrawingManager.setMap(null);
        }
      );
    } else {
      drawingManager?.setMap(null);
      polygon?.setMap();
      setPolygon();
    }
  };
  const handleViewPortChange = ({ center, zoom, bounds, marginBounds }) => {
    setCurrentBounds(bounds);
    setZoom(zoom);
  };
  const handleApiLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);
    const bounds = getBounds(map);
    setCurrentBounds(bounds);
  };
  const onSubmitAction = (action, note) => {
    if (!action) {
      enqueueSnackbar("Action is required!", { variant: "error" });
      return;
    }
    if (!polygon) {
      enqueueSnackbar("Please create a polygon first!", { variant: "error" });
      return;
    }
    setLoading(true);
    const vertices = polygon.getPath();
    let polygonCoords = [];
    for (let i = 0; i < vertices.getLength(); i++) {
      const xy = vertices.getAt(i);
      polygonCoords = [...polygonCoords, { lat: xy.lat(), lng: xy.lng() }];
    }
    const body = {
      action: action,
      note: note,
      polygon: JSON.stringify(polygonCoords),
      viewIdn: view.id,
      bounds: currentBounds,
      zoom: zoom,
    };
    const request = postAction(body);
    (async () => {
      try {
        const result = await request;
        enqueueSnackbar(`Action Submitted Succesfully`, { variant: "success" });
      } catch (e) {
        enqueueSnackbar(
          `Submitting action failed: ${e.response.data.message}`,
          { variant: "error" }
        );
      }
      setLoading(false);
    })();
    return () => {
      request.cancel();
      setLoading(false);
    };
  };
  return (
    <Wrapper>
      <LeftBar className={leftSidebar ? "sidebar open" : "sidebar"}>
        {loading && <LoadingOverlay />}
        <Filter
          onSubmit={onChangeFilter}
          totalItems={totalItems}
          onChangeView={onChangeView}
          resetFilter={resetFilter}
          setResetFilter={setResetFilter}
        />
        <ZipFilter onSubmit={onChangeZipFilter} totalItems={totalItemsZip} />
        <button
          onClick={handleViewSidebar}
          className={leftSidebar ? "sidebar-toggle open" : "sidebar-toggle"}
        >
          <i className={leftSidebar ? "arrow left" : "arrow right"}></i>
        </button>
      </LeftBar>
      <RightBar className={leftSidebar ? "content open" : "content"}>
        {loading && <LoadingOverlay />}
        <div style={{ height: "80vh", width: "100%", margin: "auto" }}>
          <Map
            items={items}
            viewPortItems={viewPortItems}
            center={center}
            zoom={zoom}
            setZoom={setZoom}
            handleApiLoaded={handleApiLoaded}
            handleViewPortChange={handleViewPortChange}
          />
        </div>
        {items.length > 0 ? (
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
          >
            <Legend
              style={{ width: "30%" }}
              view={view}
              items={items}
              viewPort={viewPort}
            />
            <Actions
              style={{ width: "70%" }}
              displayPolygon={displayPolygon}
              onToggle={onToggleViewPort}
              onSubmit={onSubmitAction}
            ></Actions>
          </div>
        ) : (
          ""
        )}
      </RightBar>
    </Wrapper>
  );
}
