import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { subdivision } from '../api';
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import {makeStyles, Tooltip} from '@material-ui/core';
// import PopoverMouseOver from "../components/PopoverMouseOver";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    border: "0.5px solid",
    borderRadius: 4,
    padding: 8,
    backgroundColor: "#eaf1f7",
    color: "#000000",
    fontSize: 16
  },
}));

const { hoasGetAll } = subdivision;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const SubdivisionOtherHoas = ({ subdivisionId }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (!subdivisionId) {
      return;
    }

    setLoading(true);

    const request = hoasGetAll(subdivisionId);
    (async () => {
      try {
        const response = await request;
        setItems(response.items);
        setLoading(false);
      }
      catch (e) {
        setLoading(false);
      }
    })();

    return () => { request.cancel(); setLoading(false) };
  }, [subdivisionId]);

  const content = () => <div>
    {items.map(({ name }) => <span>{name} <br /></span>)}
  </div>;

  return (
    <Wrapper>
      {loading ? (
        <LoadingOverlay />
      ) : items.length > 0 ? (
        <Tooltip PopperProps={{ style: { marginTop: -13, marginBottom: -13 } }} classes={{
          tooltip: classes.tooltip
        }} title={content()} placement="bottom-start">
          <div>{items.length}</div>
        </Tooltip>
      ) : (
        <div>0</div>
      )}
    </Wrapper>
  );
}

export default SubdivisionOtherHoas;
