import { trimStart } from "lodash";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import config from "../../config";
import { ROUTES } from "../../constants";
import Button from "@material-ui/core/Button";

const { autoLoadingHoasOnLoad } = config;
const { MAP } = ROUTES;

const ZipFilter = ({ location, onSubmit, totalItems }) => {
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const [zip, setZip] = useState("");

  useEffect(() => {
    const searchParameters = qs.parse(trimStart(location.search, "?"));
    syncStateWithProps();
    if (
      !initialized &&
      Object.keys(searchParameters).length === 0 &&
      !autoLoadingHoasOnLoad
    ) {
      return;
    }

    onSubmit(searchParameters);
  }, [location.search]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  const syncStateWithProps = () => {
    const { zip: initialZip } = qs.parse(trimStart(location.search, "?"));

    // setAddress(initialAddress);
    setZip(initialZip);
  };

  const handleZipChange = (event) => {
    setZip(event.target.value);
  };
  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleOnClick();
    }
  };

  const handleOnClick = () => {
    const stringParameters = qs.stringify({
      zip: zip || undefined,
    });

    history.push(`${MAP}?${stringParameters}${location.hash}`);
  };

  return (
    <div>
      <div
        className="wht_box"
        style={{ paddingBottom: totalItems ? "10px" : 20 }}
      >
        <h4 className="hd_box">Search by Zip</h4>
        <div className="form_item_fr">
          <div className="mb-2">
            <TextField
              size="small"
              fullWidth
              label="Zip"
              variant="outlined"
              value={zip}
              onChange={handleZipChange}
              onKeyUp={handleKeyUp}
            />
          </div>
        </div>
        <div className="btn_box">
          <Button variant="contained" color="primary" onClick={handleOnClick}>
            Search
          </Button>
        </div>
        {totalItems ? (
          <div className="form_item_fr">
            <div
              className="mb-2"
              style={{
                padding: 0,
                color: "#2c4773",
                margin: 0,
                textAlign: "center",
              }}
            >
              {totalItems} results found
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default withRouter(ZipFilter);
