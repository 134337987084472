import { DataGrid } from "@material-ui/data-grid";
import React from 'react';
import styled from "styled-components";
import ActionButtons from "./ActionButtons";
import ViewDocButtons from "./ViewDocButtons";
import GridCellExpand from "../GridCellExpand";
import PropTypes from 'prop-types'
import { COLUMN_WIDTH } from "../../constants";
//import DocumentDeleteButton from "./DocumentDeleteButton";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  // margin: 10px 0 0 10px
`;

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const ActionCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const Documents = ({ items, ofHoa, onDisconnect, OnView }) => {
  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }
  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };
  const columns = [
    {
      field: 'documentName',
      headerName: 'Document Name',
      renderCell: renderCellExpand,
      filterable: false,
      minWidth: COLUMN_WIDTH.NAME,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      renderCell: renderCellExpand,
      filterable: false,
      minWidth: COLUMN_WIDTH.TYPE,
      flex: 1,
    },
    {
      field: 'originalFileName',
      headerName: 'Original File Name',
      renderCell: renderCellExpand,
      filterable: false,
      minWidth: COLUMN_WIDTH.NAME,
      flex: 1,
    },
    {
      field: 'uploadDate',
      headerName: 'Upload Date',
      filterable: false,
      minWidth: COLUMN_WIDTH.DATE,
      flex: 1,
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   renderCell: ({ row }) => <ActionCell>
    //     <OrderingInfoAddEditButton entity={row} linkedRecordId={recordId} onEdit={onEdit} type={type} />
    //     <OrderingInfoDeleteButton
    //       orderingInfoId={row.recordId}
    //       linkedRecordId={recordId}
    //       onDelete={onDelete(row.recordId, recordId)}
    //       type={type}
    //     />
    //   </ActionCell>,
    //   width: 150,
    // },
    {
      field: 'action',
      headerName: 'Action',
      filterable: false,
      renderCell: ({ row: { documentId } }) =>
        <ActionCell>
          <ViewDocButtons onDisconnect={handleOnView(documentId)} documentId={documentId} />
          <ActionButtons onDisconnect={handleOnDisconnect(documentId)} documentId={documentId} />
        </ActionCell>,
      minWidth: COLUMN_WIDTH.ACTION,
      flex: 1,
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   renderCell: ({ row: { documentId } }) => <ActionCell>      
    //     <ActionButtons onDisconnect={handleOnDisconnect(documentId)} documentId={documentId} />

    //     <DocumentDeleteButton
    //       orderingInfoId={row.recordId}
    //       linkedRecordId={recordId}
    //       onDelete={onDelete(row.recordId, recordId)}
    //       type={type}
    //     />
    //   </ActionCell>,
    //   width: 150,
    // },
  ];

  const handleOnDisconnect = (documentId) => () => {
    onDisconnect(documentId);
  }

  const handleOnView = (documentId) => () => {
    OnView(documentId);
  }

  return <Wrapper>
    <div class="set4_tab">
      <HeaderWrapper>
        <h3 class="hd_tg mb-3">Documents</h3>
      </HeaderWrapper>
      <div style={{ height: '320px', width: '100%' }}>
        {/* items.map - brute fix to keep the ID for the DataGrid component. */}
        <DataGrid rows={items.map((record) => ({ id: record.documentId, ...record }))} columns={columns} rowsPerPageOptions={[]} />
        {/* <DataGrid rows={items} columns={columns} rowsPerPageOptions={[]} /> */}
      </div>
    </div>
  </Wrapper>
}

export default Documents;
