import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../../components/Marker";

function Map({items, viewPortItems, center, zoom, handleApiLoaded, handleViewPortChange}) {
  
  const [mainColor, setMainColor] = useState("#5491f5");
  const [secondaryColor, setSecondaryColor] = useState("red");

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyCdyGqtMAV0HWvtjun6FTGkmhFmqwWj5Ns",
        libraries: ["drawing"],
      }}
      onChange={handleViewPortChange}
      center={center}
      zoom={zoom}
      yesIWantToUseGoogleMapApiInternals //this is important!
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    >
      {items.map((property) => {
        return (
          <Marker
            key={property.id}
            id={property.id}
            lat={property.lat}
            lng={property.long}
            name={`Property Source ID: ${property.id}\nProperty Address: ${
              property.address
            }, ${property.city}, ${property.state} ${property.zip}\n${
              property.keyText ? property.keyText : ""
            }`}
            address={`${property.address}+%2C${property.city}%2C${property.state}+${property.zip}`}
            color={property.keyColor ?? mainColor}
          />
        );
      })}
      {viewPortItems.map((property) => {
        return (
          <Marker
            key={property.id}
            id={property.id}
            lat={property.lat}
            lng={property.long}
            name={`Property Source ID: ${property.id}\nProperty Address: ${property.address}, ${property.city}, ${property.state} ${property.zip}`}
            address={`${property.address}+%2C${property.city}%2C${property.state}+${property.zip}`}
            color={secondaryColor}
          />
        );
      })}
    </GoogleMapReact>
  );
}

export default Map;
