import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from "notistack";
import React, { useContext, useState } from 'react';
import {cloneDeep} from 'lodash';
import AppContext from '../context'
import { hoa, mgmtCompany } from "../api/endpoints";
import { ORDERING_INFO_TYPES } from "../constants";
import LoadingOverlay from "./Loading/LoadingOverlay";

const { orderingInfoDelete: hoaDelOrderingInfo } = hoa;
const { orderingInfoDelete: mgmtCompanyDelOrderingInfo } = mgmtCompany;

const OrderingInfoDeleteDialog = ({ orderingInfoId, linkedRecordId, onClose, open, type }) => {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  //Context
  const { hoaDetails, onSubmit } = useContext(AppContext);

  const handleOk = async () => {
    setLoading(true);

    try {
      let hoaCreationData = cloneDeep(hoaDetails);

      let orderingInfoIds = [];
      let orderingInfoContactIds = [];

      // Populate ordering infos ids that has to be deleted
      orderingInfoIds.push({Master_OrdInfo_ID: orderingInfoId, Record_IDN: null})

      // Populate ordering infos contact ids that has to be deleted
      const orderingInfoindex = hoaCreationData.OrderingInfo.findIndex(item => Number(item.Master_OrdInfo_ID) === Number(orderingInfoId));
      if(orderingInfoindex !== -1){
        const contactId = hoaCreationData.OrderingInfo[orderingInfoindex].Master_Contact_ID;
        if(contactId){
          orderingInfoContactIds = hoaCreationData.Contacts
          .filter(item => Number(item.Master_Contact_ID) === Number(contactId))
          .map(item => {
            return {
              Master_Contact_ID: item.Master_Contact_ID,
              Record_IDN: null
            }
          }) 
        }
      }
      
      hoaCreationData.OrderingInfoDelete = orderingInfoIds
      hoaCreationData.ContactsDelete = orderingInfoContactIds;

      console.log('hoaCreationData', hoaCreationData)

      await onSubmit(hoaCreationData)

      // enqueueSnackbar('The Ordering Info has been deleted', { variant: 'success' });
      onClose(true);
    } catch (e) {
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while deleting the Ordering Info: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }

    setLoading(false);
  }

  const handleCancel = () => {
    onClose();
  }

  const onDialogClose = () => {
    if (loading) {
      enqueueSnackbar('Wait the action to be completed', { variant: 'warning' });
    } else {
      onClose();
    }
  }

  return (
    <Dialog onClose={onDialogClose} open={open}>
      {loading && <LoadingOverlay />}
      <div class="h2_popup">
      <DialogTitle>Confirm the action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deleting Ordering Info cannot be undone!<br />
        </DialogContentText>
      </DialogContent>
      <div class="popup_btn">
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button color="secondary" onClick={handleOk}>Delete</Button>
        </DialogActions>
      </div>
      </div>
    </Dialog>
  );
}

export default OrderingInfoDeleteDialog;
