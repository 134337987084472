import { get as requestGet, post as requestPost } from "../base/request";
import options from "../servers/apiV5";

export const getPropertiesBySubdivision = (name, fips) =>
  requestGet("properties/subdivision", options(), {
    name: name ?? undefined,
    fips: fips ?? undefined,
  });
export const getPropertiesByZip = (zip) =>
  requestGet("properties/zip", options(), {
    zip: zip ?? undefined,
  });
export const getPropertiesInViewPort = (currentBounds) =>
  requestPost(`properties/viewport`, options(), currentBounds);
export const getViews = () => requestGet("views", options(), {});
export const getViewProperties = (id) => requestGet(`views/properties/${id}`, options(), {});
export const getKeys = (id) => requestGet(`keys/${id}`, options(), {});
export const postAction = (body) =>
  requestPost(`action`, options(), body);
