import { DataGrid } from "@material-ui/data-grid";
import React from 'react';
import styled from "styled-components";
import GridCellExpand from "../GridCellExpand"
import { COLUMN_WIDTH } from "../../constants";
import PropTypes from 'prop-types';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const div_div = {
  height: '500px',
}

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const KnownProps = ({ items }) => {
  function renderCellExpand(params) {
    // const county = FIPS[params?.row?.fips] ? FIPS[params?.row?.fips].county : 'INCORRECT FIPS CODE';
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }
  const columns = [
    {
      field: "address1",
      headerName: "Address 1",
      renderCell: ({ row: { address1 } }) => (
        <GridCellExpand value={address1} width={150} />
      ),
      minWidth: COLUMN_WIDTH.ADDRESS,
      flex: 1,
    },
    {
      field: "address2",
      headerName: "Address 2",
      renderCell: ({ row: { address2 } }) => (
        <GridCellExpand value={address2} width={150} />
      ),
      minWidth: COLUMN_WIDTH.ADDRESS,
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      renderCell: ({ row: { city } }) => (
        <GridCellExpand value={city} width={150} />
      ),
      minWidth: COLUMN_WIDTH.CITY,
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      minWidth: COLUMN_WIDTH.STATE,
      flex: 1,
    },
    {
      field: "zip",
      headerName: "Zip",
      minWidth: COLUMN_WIDTH.ZIP,
      flex: 1,
    },
    {
      field: "zip4",
      headerName: "Zip + 4",
      minWidth: COLUMN_WIDTH.ZIP,
      flex: 1,
    },
    {
      field: "pbId",
      headerName: "PB ID",
      minWidth: COLUMN_WIDTH.ID,
      flex: 1,
    },
  ];

  return <Wrapper>
    <div class="set4_tab">
      <HeaderWrapper>
        <h3 class="hd_tg">Associated Properties</h3>
      </HeaderWrapper>
      <div class="tab_dev">
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ height: '450px' }} class="div_row">
            <DataGrid disableColumnFilter rows={items.map((record) => ({ id: record.pbId, ...record }))} columns={columns} rowsPerPageOptions={[]} />
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
}

export default KnownProps;
