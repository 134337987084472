import React, { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import "./Marker.css";
import { useSnackbar } from "notistack";

const Marker = (props) => {
  const { color, name, id, address, lat, lng } = props;
  const [contextMenu, setContextMenu] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };
  const handleCopy = () => {
    navigator.clipboard
      .writeText(id)
      .then(
        enqueueSnackbar("Property Source ID copied", { variant: "success" })
      );
    setContextMenu(null);
  };
  return (
    <div>
      <div
        onClick={handleClick}
        className="pin bounce"
        style={{ backgroundColor: color, cursor: "pointer" }}
        title={name}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleCopy}>Copy Source ID</MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            target={"_blank"}
            href={`https://www.google.com/maps/search/?api=1&query=${address}`}
            style={{ textDecoration: "none", color: "black" }}
          >
            View Property on Google Maps
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            target={"_blank"}
            href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`}
            style={{ textDecoration: "none", color: "black" }}
          >
            Google Street View Here
          </a>
        </MenuItem>
      </Menu>
      {/* <div className="pulse" /> */}
    </div>
  );
};

export default Marker;
