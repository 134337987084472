import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from "notistack";
import {cloneDeep} from 'lodash';
import AppContext from '../context'
import React, { useContext, useState } from 'react';
import { hoa, mgmtCompany } from "../api/endpoints";
import { CONTACT_TYPES } from "../constants";
import LoadingOverlay from "./Loading/LoadingOverlay";

const { contactDelete: hoaDelContact } = hoa;
const { contactDelete: mgmtCompanyDelContact } = mgmtCompany;

const ContactDeleteDialog = ({ entity, contacts, contactId, linkedRecordId, onClose, open, type }) => {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  //Context
  const { hoaDetails, onSubmit } = useContext(AppContext);

  const handleOk = async () => {
    setLoading(true);

    try {
      // Validate when deleting prioritized contact
      if(entity.priority === 'Y' && contacts.length > 1){
        onClose(true);
        throw {
          response: {
            data: {
              message: `You cannot remove a primary contact unless it is not the last one`
            }
          }
        }
      }

      let hoaCreationData = cloneDeep(hoaDetails);
      hoaCreationData.ContactsDelete.push({Master_Contact_ID: contactId, Record_IDN: null})
      await onSubmit(hoaCreationData)
      
      // enqueueSnackbar('The Contact has been deleted', { variant: 'success' });
      onClose(true);
    } catch (e) {
      console.error('bhens',e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while deleting the Contact: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }

    setLoading(false);
  }

  const handleCancel = () => {
    onClose();
  }

  const onDialogClose = () => {
    if (loading) {
      enqueueSnackbar('Wait the action to be completed', { variant: 'warning' });
    } else {
      onClose();
    }
  }

  return (
    <Dialog onClose={onDialogClose} open={open}>
      {loading && <LoadingOverlay />}
      <DialogTitle>Confirm the action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deleting Contact cannot be undone!<br />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button color="secondary" onClick={handleOk}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContactDeleteDialog;
