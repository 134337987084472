import {
  del as requestDel,
  get as requestGet,
  patch as requestPatch,
  post as requestPost,
  put as requestPut,
} from '../base/request';
import options from '../servers/apiV5';

export const contactCreate = (hoaIdn, contact) =>
  requestPost(`hoa/${hoaIdn}/contact`, options(), contact);

export const contactDelete = (hoaId, contactId) =>
  requestDel(`hoa/${hoaId}/contact/${contactId}`, options());

export const contactPatch = (hoaIdn, contactId, payload) =>
  requestPatch(`hoa/${hoaIdn}/contact/${contactId}`, options(), payload);

export const del = (recordId) =>
  requestDel(`hoa/${recordId}`, options());

export const get = (recordId) =>
  requestGet(`hoa/${recordId}`, options());

export const getHoaDetailsByMasterId = (recordId) =>
  requestGet(`hoa-details/${recordId}`, options());

// @todo Rename to contactsGet
export const getContacts = (hoaIdn) =>
  requestGet(`hoa/${hoaIdn}/contact`, options())

// @todo Rename to getAll
export const getHoas = (
  {
    limit = 20,
    filter: {
      address,
      city,
      name,
      masterId,
      state,
      zip,
      offset,
    } = {}
  } = {}
) =>
  requestGet('hoa', options(), {
    limit,
    offset,
    city: city ?? undefined,
    name: name ?? undefined,
    masterId: masterId ?? undefined,
    state: state === null ? 'null' : state,
    address: address ?? undefined,
    zip: zip ?? undefined,
  });

export const orderingInfoCreate = (hoaIdn, orderingInfo) =>
  requestPost(`hoa/${hoaIdn}/orderingInfo`, options(), orderingInfo);

export const orderingInfoDelete = (hoaId, orderingInfoId) =>
  requestDel(`hoa/${hoaId}/orderingInfo/${orderingInfoId}`, options());

export const orderingInfoGet = (hoaIdn) =>
  requestGet(`hoa/${hoaIdn}/orderingInfo`, options())

export const orderingInfoPatch = (hoaIdn, orderingInfoId, payload) =>
  requestPatch(`hoa/${hoaIdn}/orderingInfo/${orderingInfoId}`, options(), payload);

export const patch = (recordId, payload) =>
  requestPatch(`hoa/${recordId}`, options(), payload);

//to create hoa
export const createNewHoa = (payload) =>
  requestPost(`hoa/create`, options(), payload);

export const hoaIngestion = (payload) =>
  requestPost(`hoa/ingestion`, options(), payload);

export const subdivisionsGet = (hoaIdn) =>
  requestGet(`hoa/${hoaIdn}/subdivision`, options())

export const subdivisionConnect = (hoaIdn, subdivisionId) =>
  requestPut(`hoa/${hoaIdn}/subdivision/${subdivisionId}`, options());

export const subdivisionCreate = (hoaIdn, subdivision) =>
  requestPost(`hoa/${hoaIdn}/subdivision/`, options(), subdivision);

export const subdivisionCreateStandalone = (subdivision) =>
  requestPost(`subdivision/create`, options(), subdivision);

export const subdivisionDisconnect = (hoaIdn, subdivisionId) =>
  requestDel(`hoa/${hoaIdn}/subdivision/${subdivisionId}`, options());

export const listDocument = (hoaIdn) =>
  requestGet(`documents/${hoaIdn}`, options());

export const deleteDocument = (id) =>
  requestPost(`documents/delete/${id}`, options());

export const createDocument = (hoaId, formData) =>
  requestPost(`documents/${hoaId}/create`, options(), formData);

export const uploadDocument = (hoaId, formData) => {
  console.log("form data => ", formData)
  requestPost(`documents/file/${hoaId}`, options(), formData);
}

export const downloadDocument = (hoaId) =>
  requestGet(`documents/file/download/${hoaId}`, options());