import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ROUTES } from "../constants";
import Layout from '../containers/App/Layout';
import HoaMaster from "../containers/HoaMaster";
import HoaId from "../containers/HoaId";
import SubMaster from "../containers/SubMaster";
import VisualMap from "../containers/VisualMap";
import { useAuth } from '../utils/auth'
import Logout from './Logout';
import ManagementCompanies from '../containers/ManagementCompanies';

const { HOA_MASTER, SUB_MASTER, MANAGEMENT, HOAID , MAP} = ROUTES;

const AuthenticatedApp = ({ location }) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    const currentPath = `${location.pathname}${location.search}${location.hash}`;
    return <Redirect to={`/login?redirect=${encodeURIComponent(currentPath)}`} />;
  }

  return (
    <Switch>
      <Layout>
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/' component={HoaMaster} />
        <Route path={`${HOA_MASTER}/:recordId?`} component={HoaMaster} />
        <Route path={`${SUB_MASTER}/:recordId?`} component={SubMaster} />
        <Route path={`${MANAGEMENT}/:recordId?`} component={ManagementCompanies} />
        <Route path={`${HOAID}/:recordId?`} component={HoaId} />
        <Route path={`${MAP}/:subdivisionName?`} component={VisualMap} />
        {/* @todo Show 404 if the page not found */}
      </Layout>
    </Switch>
  );
};

export default withRouter(AuthenticatedApp);
