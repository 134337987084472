import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { cloneDeep, trimStart } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { mgmtCompany } from "../../api";
import styled from "styled-components";
import Contacts from "./Tabs/Contacts";
import CompanyDetails from "./Tabs/CompanyDetails";
import OrderingInfo from "./Tabs/OrderingInfo";
//latest add
import AppContext from "../../context";
import HoaInfo from "./Tabs/HoaInfo";
import CreateCompany from "./Tabs/CreateCompany";
import { mgmtDetailsTransformer } from "../../utils/hoa";
import { ORDERING_INFO_PROCESS_METHODS } from "../../constants";

const { getMgmtDetailsByMasterId, mgmtIngestion } = mgmtCompany;

const TabPanelWrapper = styled.div`
  height: 100%;
`;

const StyledBox = styled(Box)`
  height: 100%;
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelWrapper role="tabpanel" hidden={value !== index} {...other}>
      <StyledBox>{children}</StyledBox>
    </TabPanelWrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
const WrapperInternal = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const tabNames = [
  "company-details",
  "contacts",
  "ordering-info",
  "hoa-info",
  "create-company",
];

const Content = ({
  company,
  location,
  onChangeHasChanges,
  onDelete,
  onUpdate,
  show,
  showFunction,
  ItemsList,
  isReview,
  totalItems,
  isStandaloneCreate,
  match
}) => {
  const [tabNum, setTabNum] = useState(0);
  const [hasChangesMgmtCompany, setHasChangesMgmtCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mgmtDetails, setMgmtDetails] = useState({
    MGMT: {
      "ExtSystem.User_ID": "",
      Code: null,
      EIN: null,
      Mailing_City: null,
      Mailing_Line1: null,
      Mailing_Line2: null,
      Mailing_State: null,
      Mailing_Zip: null,
      Mailing_Zip_4: null,
      Master_Mgmt_Co_ID: null,
      Name: null,
      Notes: null,
      Record_IDN: null,
      Verified_Date: null,
      Website: null
    },
    Contacts: [],
    OrderingInfo: [],
    ContactsDelete: [],
    OrderingInfoDelete: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const [showTab, setShowTab] = useState();

  const getMgmtDetails = async(recordId) => {
    if(recordId){
      try{
        const resData = await getMgmtDetailsByMasterId(recordId);
        console.log("MGMT details from property DB", resData);
        const data = resData.Data;
        const mgmtObj = mapPDBDatatoInMemoryObj(data);
        setMgmtDetails(mgmtObj)
      }
      catch (e) {
        enqueueSnackbar("Could not load the selected management company!", {
          variant: "error",
        });
      }
    }
  }

  const getContactDetails = (el, contactInfo) => {
    switch (el.Proc_Method) {
      case ORDERING_INFO_PROCESS_METHODS.Phone:
        return contactInfo.Phone_Primary;
      case ORDERING_INFO_PROCESS_METHODS.Mail:
        return contactInfo.Mailing_Address;
      case ORDERING_INFO_PROCESS_METHODS.Email:
        return contactInfo.Email;
      case ORDERING_INFO_PROCESS_METHODS.Fax:
        return contactInfo.Fax;
      case ORDERING_INFO_PROCESS_METHODS.Website:
        return contactInfo.Website;
    }
  }

  const mapPDBDatatoInMemoryObj = (data) => {
    let mgmtObj = {
      MGMT: {
        "ExtSystem.User_ID": "",
        Code: null,
        EIN: null,
        Mailing_City: null,
        Mailing_Line1: null,
        Mailing_Line2: null,
        Mailing_State: null,
        Mailing_Zip: null,
        Mailing_Zip_4: null,
        Master_Mgmt_Co_ID: null,
        Name: null,
        Notes: null,
        Record_IDN: null,
        Verified_Date: null,
        Website: null
      },
      Contacts: [],
      OrderingInfo: [],
      ContactsDelete: [],
      OrderingInfoDelete: [],
    };

    // Map Management Company
    if (data.MGMT.length) {
      const MGMTData = data.MGMT[0];
      mgmtObj.MGMT = {
        "ExtSystem.User_ID": "",
        Code: MGMTData.Arms_Code || "",
        Master_Mgmt_Co_ID: MGMTData.Master_Mgmt_Co_ID || null,
        EIN: MGMTData.Arms_Ein || "",
        Email: MGMTData.Email,
        Mailing_City: MGMTData.City,
        Mailing_Line1: MGMTData.Mailing_Address || "",
        Mailing_Line2: MGMTData.Mailing_Line2 || "",
        Mailing_State: MGMTData.State,
        Mailing_Zip: MGMTData.Zip || "",
        Mailing_Zip_4: MGMTData.Zip_4 || "",
        Name: MGMTData.Name,
        Notes: MGMTData.Notes,
        Phone_Primary: MGMTData.Phone_Primary,
        Record_IDN: null,
        Verified_Date: MGMTData.Verified_Date,
        Website: MGMTData.Website
      };
    }

    // Map Contacts
    if (data["Relation data"] && data["Relation data"].Contacts && data["Relation data"].Contacts.length && data["Relation data"].Contacts[0]["Contacts Detail"] !== "No Data") {
      //need to filter out ordering info contacts because they will be included as well
      const contactsData = data["Relation data"].Contacts[0]["Contacts Detail"]
      mgmtObj.Contacts = contactsData;
    }

    // Map Ordering Info
    if (data["Relation data"] && data["Relation data"].OrderingInfo && data["Relation data"].OrderingInfo.length && data["Relation data"].OrderingInfo[0]["Ordering Info Detail"] !== "No Data") {

      const validOrderingInfos = data["Relation data"].OrderingInfo[0]["Ordering Info Detail"].filter(function (el) {
        if (el.Proc_Org_Type !== "Other Agency") {
          //el.isInvalid = false;
          return el
        }
      });

      let tmpArr = [];
      // Compare the contacts related ordering info and fill the ordering info object with required fields
      validOrderingInfos.map(function (el) {
        //each ordering info line can have only one contact for details by definition and this is only for pull using using master id
        if ((data["Relation data"].Contacts[0]["Contacts Detail"] !== 'No Data') && Array.isArray(data["Relation data"].Contacts[0]["Contacts Detail"])) {
          var relContactList = data["Relation data"].Contacts[0]["Contacts Detail"].filter(function (revContactItem) {
            var retval = false;
            if (el.Deliverable_Type !== "" && el.Deliverable_Type !== null && revContactItem.Category !== "" && revContactItem.Category !== null && revContactItem.Category.toUpperCase() === el.Deliverable_Type.toUpperCase() && (((el.Proc_Org_Type == null || el.Proc_Org_Type == '') && (revContactItem.Object_Type === el.Owner_Org_Type && (revContactItem.Master_Object_ID === el.Master_Owner_Org_ID))) || ((el.Proc_Org_Type !== null && el.Proc_Org_Type !== '') && (revContactItem.Object_Type === el.Proc_Org_Type && (revContactItem.Master_Object_ID === el.Master_Proc_Org_ID))) || ((el.Proc_Org_Type !== null && el.Proc_Org_Type !== '') && (revContactItem.Object_Type === el.Proc_Org_Type && (el.Proc_Org_Type === el.Owner_Org_Type && revContactItem.Master_Object_ID === el.Master_Owner_Org_ID))))) {
              retval = true;
            }
            return retval;
          });
          var revisedOrdInfo = el;
          if (relContactList.length > 0) {
            //take the first match
            var contactItem = relContactList[0];
            if (relContactList.length > 1) {
              //this should not happen - since it is happening lets console log this
              console.log("More than 1 contact matches for ordering info Row for ordering info: ", el);
              console.log("contact matches are : ", relContactList);
            }
            revisedOrdInfo = {
              Master_OrdInfo_ID: el.Master_OrdInfo_ID,
              Master_Proc_Org_ID: mgmtObj.MGMT.Master_Mgmt_Co_ID,
              Master_Owner_Org_ID: mgmtObj.MGMT.Master_Mgmt_Co_ID,
              Amount: el.Amount,
              Amount_Text: null,
              Assn_Info_IDN: null,
              //adding master contact id to ordering info object so contacts can be generated for write back to prop db
              Master_Contact_ID: contactItem.Master_Contact_ID || null,
              Master_Object_ID: contactItem.Master_Object_ID || null,
              Contact_Details: getContactDetails(el, contactItem),
              Contact_Name: contactItem.Contact_Name,
              Deliverable_Type: el.Deliverable_Type,
              Notes: el.Notes,
              Notes_Text: null,
              Payment_Type: el.Payment_Type,
              Payment_Type_Text: null,
              Proc_Method: el.Proc_Method,
              Proc_Org_Name: null,
              Proc_Org_Type: el.Proc_Org_Type,
              Proc_Org_IDN: el.Master_Proc_Org_ID,
              Owner_Org_Type: el.Owner_Org_Type,
              Owner_Org_IDN: el.Master_Owner_Org_ID,
              Record_IDN: null
            };
          } else {
            //no contact found for ordering info
            console.log("ordering info added without a contact is ", revisedOrdInfo);
          }
          tmpArr.push(revisedOrdInfo);
        }
      });
      if (tmpArr.length > 0) {
        mgmtObj.OrderingInfo = tmpArr;
      } 
      else {
        mgmtObj.OrderingInfo = validOrderingInfos;
      }
      } 
      else {
      mgmtObj.OrderingInfo = []
    }

    console.log('mgmt details after mapping PDB data', mgmtObj);

    return mgmtObj
  }

  useEffect(async() => {
    const recordId = match.params.recordId;
    setLoading(true);
    await getMgmtDetails(recordId)
    setLoading(false);
  }, [match.params.recordId])

  useEffect(() => {
    setShowTab(show);
    if (!show) {
      history.push(`${location.pathname}${location.search}#${tabNames[4]}`);
    } else {
      history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
    }
  }, [show]);

  useEffect(() => {
    onChangeHasChanges(hasChangesMgmtCompany);
  }, [hasChangesMgmtCompany]);

  useEffect(() => {
    history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
  }, [hasChangesMgmtCompany]);

  useEffect(() => {
    const tabName = trimStart(location.hash, "#");
    const tabIndex = tabNames.indexOf(tabName);
    setTabNum(tabIndex === -1 ? 0 : tabIndex);
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    if (!hasChangesMgmtCompany) {
      history.push(
        `${location.pathname}${location.search}#${tabNames[newValue]}`
      );
    } else {
      enqueueSnackbar("You need to save or cancel the changes first.", {
        variant: "error",
      });
    }
  };

  const onUpdateMgmtCompany = (details) => {
    const entity = mgmtDetailsTransformer(details)
    onUpdate({
      ...entity.MGMT
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      console.log('submit mgmt Obj', data)

      let resObjPDB = {};
      let recordId = null;

      resObjPDB = await mgmtIngestion(data);
      console.log('PDB RES', resObjPDB);

      if(resObjPDB?.data?.status === "success" && resObjPDB?.data?.output?.MGMT?.Master_Mgmt_Co_ID){
        recordId = resObjPDB?.data?.output?.MGMT?.Master_Mgmt_Co_ID; 
      }

      // Handling missing incoming fields error
      if (resObjPDB.message === "success" && typeof resObjPDB.data === "string") {
        throw {
          response: {
            data: {
              message: resObjPDB.data
            }
          }
        }
      }

      // // Handling the missing id error
      // if(resObjPDB?.data?.status && (resObjPDB?.data?.status === "Failed:  Record not Found" || resObjPDB?.data?.status.indexOf('Record not Found') !== -1 )){
      //   console.log('Failed:  Record not Found')
      //   const detailRes = resObjPDB.data.Detail;
      //   const resubmitDataObj = checkForMissingIds(data, detailRes);
      //   console.log('Resubmit Data Obj', resubmitDataObj);
      //   resObjPDB = await hoaIngestion(resubmitDataObj);
      //   recordId = resObjPDB?.data?.output?.HOA?.Master_Hoa_Co_ID;
      //   if(recordId){
      //     history.push(`${HOA_MASTER}/${recordId}${location.search}${location.hash}`);
      //   }
      //   setLoading(false)
      // }

      await getMgmtDetails(recordId)
      enqueueSnackbar('The HOA has been updated', { variant: 'success' });
    } catch (e) {
      console.log('error', e)
      const message = e.response.data.message;
      enqueueSnackbar(`Cannot update the HOA: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoading(false);
  }

  if (!company && !isStandaloneCreate) {
    return <>Please select a Company {totalItems ? `out of the ${totalItems} Companies found` : ''}</>;
  }
  if(isStandaloneCreate){
    return <></>;
  }

  const transformedDetails = mgmtDetailsTransformer(mgmtDetails)

  console.log('transformed data', transformedDetails)
  
  return (
    <div class="wid_dep">
      <AppContext.Provider value={{
        hoaDetails: mgmtDetails,
        transformedDetails,
        onSubmit
      }}>
        <Wrapper>
          <WrapperInternal>
            <AppBar position="static">
              {tabNum == 0 || tabNum == 4 ? '' : <span style={{ color: "black", marginTop: -12, marginBottom: 10 }}>Company Name: {transformedDetails.MGMT.name}</span>}

              <div class="tab_sty">
                <Tabs value={tabNum} onChange={handleChange}>
                  {showTab ? <Tab label="Company Details" /> : null}
                  {showTab ? <Tab label="Contacts" /> : null}
                  {showTab ? <Tab label="Ordering Info" /> : null}
                  {showTab ? <Tab label="HOA Info" /> : null}
                  {!showTab ? <Tab disabled={true} label="Create Management Company" /> : null}
                </Tabs>
              </div>
            </AppBar>
            <ContentWrapper>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={0}
              >
                <CompanyDetails
                  details={transformedDetails.MGMT}
                  mgmtCompanyRecordId={company?.recordId}
                  onChangeHasChanges={setHasChangesMgmtCompany}
                  loading={loading}
                  onDelete={onDelete}
                  onUpdate={onUpdateMgmtCompany}
                />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={1}
              >
                <Contacts
                  contacts={transformedDetails.Contacts}
                  mgmtCompanyRecordId={transformedDetails.MGMT.recordId}
                />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? null : "none" }}
                value={tabNum}
                index={2}
              >
                <OrderingInfo
                  orderingInfo={transformedDetails.OrderingInfo}
                  mgmtCompanyRecordId={transformedDetails.MGMT.recordId}
                />
              </TabPanel>
              <TabPanel style={{ display: showTab ? null : 'none' }} value={tabNum} index={3}>
                <HoaInfo managementId={transformedDetails.MGMT.recordId} companyState={transformedDetails.MGMT.state} />
              </TabPanel>
              <TabPanel
                style={{ display: showTab ? "none" : null }}
                value={tabNum}
                index={4}
              >
                <CreateCompany
                  {...company}
                  onChangeHasChanges={setHasChangesMgmtCompany}
                  onDelete={onDelete}
                  onUpdate={onUpdate}
                  changeShow={(res) => showFunction(res)}
                  companyItemsList={(res) => ItemsList(res)}
                />
              </TabPanel>
            </ContentWrapper>
          </WrapperInternal>
        </Wrapper>
      </AppContext.Provider>
    </div>
  );
};

export default withRouter(Content);
