import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

function DocumentConfirmation({ open, onClose }) {
  const handleNo = () => {
    onClose(false)
  }
  const handleYes = () =>{
    onClose(true)
  }
  return (
    <Dialog open={open}>
      <DialogTitle>Duplicate Document Name</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Warning: A document with the same name already exists, selecting yes
          will overwrite it. Do you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes}>YES</Button>
        <Button color="secondary" onClick={handleNo}>
          NO
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentConfirmation;
