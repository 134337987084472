import { DataGrid } from "@material-ui/data-grid";
import React from 'react';
import styled from "styled-components";
import { COLUMN_WIDTH } from '../../constants';
import GridCellExpand from "../GridCellExpand"
import PropTypes from 'prop-types';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const div_div = {
  height: '500px',
}

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const Properties = ({ items, count }) => {
  function renderCellExpand(params) {
    // const county = FIPS[params?.row?.fips] ? FIPS[params?.row?.fips].county : 'INCORRECT FIPS CODE';
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }
  const columns = [
    {
      field: 'address',
      headerName: 'Address',
      renderCell: ({ row: { address } }) => <GridCellExpand value={address} width={150} />,
      minWidth: COLUMN_WIDTH.ADDRESS,
      flex: 1,
    },
    {
      field: 'city',
      headerName: 'City',
      renderCell: ({ row: { city } }) => <GridCellExpand value={city} width={150} />,
      minWidth: COLUMN_WIDTH.CITY,
      flex: 1,
    },
    {
      field: 'state',
      headerName: 'State',
      wminWidth: COLUMN_WIDTH.STATE,
      flex: 1,
    },
    {
      field: 'zip',
      headerName: 'Zip',
      minWidth: COLUMN_WIDTH.ZIP,
      flex: 1,
    },
    {
      field: 'zip4',
      headerName: 'Zip + 4',
      minWidth: COLUMN_WIDTH.ZIP,
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: COLUMN_WIDTH.ID,
      flex: 1,
    },
  ];

  return <Wrapper>
    <div class="set4_tab">
      <HeaderWrapper>
        <h3 class="hd_tg">Associated Properties from Property Master {count ? `(${count} properties found)` : ''}</h3>
      </HeaderWrapper>
      <div class="tab_dev">
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ height: '600px' }} class="div_row">
            <DataGrid disableColumnFilter rows={items.map((record) => ({ id: record.id, ...record }))} columns={columns} rowsPerPageOptions={[10, 20, 50]} />
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
}

export default Properties;
