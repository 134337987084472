import {
  get as requestGet,
  patch as requestPatch,
  del as requestDel,
} from '../base/request';
import options from '../servers/apiV5';

export const getAll = ({
  name,
  state,
  fips
} = {}) =>
  requestGet('subdivision', options(), {
    name: name ?? undefined,
    state: state ?? undefined,
    fips: fips ?? undefined,
  });

export const hoasGetAll = (subdivisionId) =>
  requestGet(`subdivision/${subdivisionId}/hoa`, options());
export const getSubdivisions = (
  {
    limit = 20,
    filter: {
      name,
      state,
      fips,
      offset,
    } = {}
  } = {}
) =>
  requestGet('subdivision', options(), {
    limit,
    offset,
    name: name ?? undefined,
    state: state === null ? 'null' : state,
    fips: fips ?? undefined,
  });
export const patch = (recordId, payload) =>
  requestPatch(`subdivision/${recordId}`, options(), payload);

export const del = (recordId) =>
  requestDel(`subdivision/${recordId}`, options());

export const subdivisionsGetKnown = (name, fips) =>
  requestGet('subdivision/known', options(), {
    name: name ?? undefined,
    fips: fips ?? undefined,
  });
export const subdivisionsGetProps = (name, fips) =>
  requestGet('subdivision/properties', options(), {
    name: name ?? undefined,
    fips: fips ?? undefined,
  });