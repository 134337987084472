import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Switch,
} from "@material-ui/core";
function Actions({ onToggle, displayPolygon, onSubmit }) {
  const [note, setNote] = useState("");
  const [action, setAction] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onToggle(event.target.checked);
  };
  const handleChange2 = (event) => {
    setChecked2(event.target.checked);
    displayPolygon(event.target.checked);
  };
  const handleActionChange = (event) => {
    setAction(event.target.value);
  };
  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleOnClick();
    }
  };
  const handleOnClick = () => {
    onSubmit(action, note);
  };
  return (
    <div style={{ width: "70%" }}>
      <div className="wht_box">
        <h4 className="hd_box">Commit Action</h4>
        <div className="form_item_fr flex">
          <div className="form_item_fr" style={{ width: "80%" }}>
            <InputLabel>Show Properties in the ViewPort?</InputLabel>
          </div>
          <div className="form_item_fr trd">
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div className="form_item_fr" style={{ width: "80%" }}>
            <InputLabel>Draw Polygon</InputLabel>
          </div>
          <div className="form_item_fr trd">
            <Switch
              checked={checked2}
              onChange={handleChange2}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </div>
        <div className="form_item_fr flex">
          <div className="form_item_fr trd">
            <FormControl variant="outlined" size="small">
              <InputLabel>Action</InputLabel>
              <Select value={action} onChange={handleActionChange}>
                <MenuItem value={"Change subdivision"}>
                  Change Subdivision
                </MenuItem>
                <MenuItem value={"Combine Subdivision"}>
                  Combine Subdivisions
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="form_item_fr trd">
            <TextField
              size="small"
              fullWidth
              label="Note"
              variant="outlined"
              value={note}
              onChange={handleNoteChange}
              onKeyUp={handleKeyUp}
            />
          </div>
          <div
            style={{ marginLeft: "5px", marginTop: "18px" }}
            className="btn_box"
          >
            <Button variant="contained" color="primary" onClick={handleOnClick}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Actions;
